<template>
  <teleport to="body">
    <div class="video" :class="{'video_show': isOpened}" @click="videoStore.closeVideo()">
      <div class="video__wrapper" @click.stop="">
        <Button class="video__close" theme="icon-padding" @click="videoStore.closeVideo()">
          <draw-svg name="close" width="15" height="15"/>
        </Button>

        <iframe v-if="isOpened"
                :src="videoStore.link"
                width="100%"
                height="100%"
                frameborder="0"
                allow="accelerometer; autoplay; gyroscope; fullscreen;"
                allowfullscreen
        />

      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue'
import { useScrollLock } from '@vueuse/core'

// Components
import Button from '@/components/ui/Button'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
import { useVideoStore } from '@/store'
const videoStore = useVideoStore()

// Data
const body = ref<HTMLElement | null>(document.body)
const isLocked = useScrollLock(body)

// Computed
const isOpened = computed<boolean>(() => Boolean(videoStore.type))

// Watch
watch(() => isOpened.value, (val) => {
  isLocked.value = Boolean(val)
})

</script>

<style lang="less">
@import "@/common/less/dependence.less";

.video {
  .debug(false);
  .absoluteFillSize(@position: fixed);
  .flex();
  .transition();
  z-index: @z-index-video;
  opacity: 0;
  pointer-events: none;
  background: rgba(16, 11, 34, 0.80);
  overflow: auto;

  &_show {
    opacity: 1;
    pointer-events: all;
  }

  &__close {
    position: absolute;
    top: -50px;
    right: -50px;
  }

  &__wrapper {
    .debug(false);
    width: 100%;
    aspect-ratio: 16 / 9;
    max-width: 1008px;
    margin: auto;
    position: relative;
    background-color: @color-black;
  }
}
</style>