/* eslint-disable @typescript-eslint/no-unused-vars */
import { endpoints } from '@/network/endpoints'
import type { AxiosPromise } from 'axios'
import type { IdType, DataType } from '@/common/types'

export default class ProfileApi {
  http: any

  constructor(http: any) {
    this.http = http
  }

  async getUser(token: string | undefined = undefined): Promise<AxiosPromise> {
    let headers = {}
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return (await this.http.get(endpoints.profile.getUser, { headers })).data
  }

  async checkLogin(login: string): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.profile.sendAuthCode, { username: login })).data
  }

  async checkCode(login: string, code: string): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.profile.checkAuthCode, { username: login, authCode: code })).data
  }

  async createAccount(data: DataType): Promise<string> {
    return (await this.http.post(endpoints.profile.registration, data)).data
  }

  async changeName(data: DataType): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.profile.changeName, data)).data
  }

  async addNewCard(data: DataType): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.profile.addNewCard, data)).data
  }

  async deleteCard(id: IdType): Promise<AxiosPromise> {
    return (await this.http.delete(endpoints.profile.deleteCard(id))).data
  }

  async setDefaultCard(id: IdType): Promise<AxiosPromise> {
    return (await this.http.put(endpoints.profile.setDefaultCard(id))).data
  }

  async uploadAvatar(data: DataType): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.profile.uploadAvatar, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })).data
  }
}
