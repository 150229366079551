import inputFile from '@/components/inputs/InputFile/ru'
import taskCard from '@/components/TaskCard/ru'
import cards from '@/components/Cards/ru'
import profile from '@/views/Profile/ru'

export default {
  inputFile,
  taskCard,
  profile,
  cards,

  projectName: 'Top Seller',

  error404: 'Страница не найдена',

  statuses: {
    new: 'Новое',
    awaitPayment: 'Ожидает оплаты',
    awaitReview: 'Ожидает отзыва',
    done: 'Выплачено',
    error: 'Ошибка',
  },

  validate: {
    required: 'Необходимо заполнить это поле',
    card: 'Карта должна состоять минимум из 16 цифр',
  },

  common: {
    error: 'Ошибка',
    noData: 'Нет данных...',
    backToMain: 'Вернуться на главную',

    bot: {
      link: 'https://t.me/TopsellerMP_bot',
      name: 'TopsellerMP',
    },

    buttons: {
      chat: {
        title: 'Чат с поддержкой',
        link: 'http://tg.com',
      },

      channel: {
        title: 'Наш канал',
        link: 'http://tg.com',
      },
    },
  },

  hi: 'Привет',
  login: 'Войти',
  logout: 'Выйти',
  signin: 'Зарегистрироваться',
  copy: '©️ 2023. Все права защищены.',

  myTasks: 'Мои задания',
  newTasks: 'Новые задания на выкуп',
  status: 'Статус',
  choose: 'Выбрать',

  sortBy: 'Сортировать по',

  task: {
    404: 'Такой задачи не существует',

    video: {
      title: 'Видео-инструкция к заданию',
      desc: 'Обязательна к просмотру перед выполнение задания',
      link: 'Открыть текстовую инструкцию',
    },

    controls: {
      back: {
        first: 'К выбору заданий',
        other: 'Назад',
      },
      next: {
        first: 'Приступить',
        other: 'Следующий шаг',
        last: 'Завершить',
      },
    },
  },

  registrationModal: {
    title: 'Регистрация',
    text: 'Чтобы получить код - перейдите по этой ссылке:',
    name: 'Ваше имя:',
    login: 'Ваш логин в ТГ:',
    agree: {
      text: 'Согласие на ',
      link: 'обработку моих персональных данных.',
    },
    hasAccount: {
      text: 'Уже есть аккаунт? Выполните',
      link: 'вход.',
    },
    getCode: 'Код из телеграм-бота:',
    submit: {
      first: 'Зарегистрироваться',
      second: 'Подтвердить',
    },
    hint: 'Не пришел код? Напишите в поддержку TopSeller.',
  },

  loginModal: {
    title: 'Добро пожаловать в TopSeller!',
    text: 'Войдите с помощью телеграм-бота:',
    login: 'Ваш логин в ТГ:',
    getCode: 'Получить код',
    confirm: 'Подтвердить',
    useLogin: 'Логин',
    hint: 'Не пришел код? Напишите в поддержку TopSeller.',
  },

  badge: {
    awaitPayment: 'Ожидает оплаты',
    awaitReview: 'Ожидает отзыва',
    done: 'Выплачено',
    error: 'Ошибка в задании',
  },

  conditions: {
    title: 'Условия',
    refund: 'Возврат',
    full: 'Полное возмещение',
  },



  manHas: 'у человека',
  hands: 'рук | рука | руки | рук',
  welcome: 'Здесь ты найдешь новые задания и историю выполненных заказов',

  earned: 'Ты заработал',

  footer: {
    privacy: 'Политика конфиденциальности',
    agreements: 'Пользовательское соглашение',
    offer: 'Договор оферты',
    call: {
      title: 'Позвонить',
      desc: 'Номер поддержки TopSeller',
    },
    write: {
      title: 'Написать',
      desc: 'Отправить нам письмо на e-mail',
    },
    toClients: 'Клиентам',
  },

  main: {
    unAuth: {
      title: 'Увеличьте свой доход с TopSeller',
      desc: 'Уникальная платформа для совершения выкупов на Wildberries с быстрыми платежами за задания',
      register: 'Зарегистрироваться',
      features: {
        events: 'Ежедневные задания',
        fastPayments: 'Быстрые выплаты',
      },
    },

    auth: {
      empty: {
        notFound: 'По заданным фильтрам нет результатов...',
        myTasks: 'У вас пока нет заданий...',
        newTasks: 'Новых заданий пока нет...',
      },

      filter: {},
    },
  },

}
