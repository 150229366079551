<template>
  <div class="page" v-if="commonStore.init" :class="{'page_unAuth': !isUserAuth, 'page_loading': commonStore.loading}">

      <Header class="page__header"/>

      <main class="page__content">
        <slot></slot>
      </main>

      <Footer class="page__footer"/>
  </div>

  <Modal
      v-if="modalStore.show"
      @close="closeModal"
      :loading="modalStore.loading"
  >
    <component :is="isComponent"/>
  </Modal>

  <Video />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Component } from 'vue'

// Store
import { useAuthModalStore, useCommonStore } from '@/store'

const commonStore = useCommonStore()
const modalStore = useAuthModalStore()

// Components
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Modal from '@/components/ui/Modal'
import Registration from '@/components/Registration'
import Login from '@/components/Login'
import Video from '@/components/ui/Video'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Router
import { useRoute } from 'vue-router'
const route = useRoute()

// Computed
const isUserAuth = computed<boolean>(() => Boolean(commonStore.user))
const isComponent = computed<Component | void>(() => {
  switch (modalStore.component) {
    case 'Registration':
      return Registration
    case 'Login':
      return Login
  }
})

// Methods
function closeModal(): void {
  modalStore.toggleModal(false)
}

</script>

<style lang="less">
@import "@/common/less/dependence.less";

html {
  .square(100%);
  .box-sizing();
  margin: 0;
  padding: 0;
  scrollbar-gutter: stable;

  &.stop-scroll {
    .square(100%);
    position: fixed;
    overflow: hidden;
  }
}

body {
  min-width: 320px;
  color: @color-white;
  font-family: @font-default;
  font-weight: 400;
  font-size: @text-size-default;
  scrollbar-gutter: stable;

  &.loading * {
    .transition(~'none !important');
  }

  * {
    .box-sizing();
  }
}

body,
#app {
  .size(100%, 100%);
  .box-sizing();
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: @color-white;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.page {
  .debug(false);
  .size(100%, auto);
  .flex(flex-start, center, column);
  .loading(@position: fixed, @z-index-loading: 5000);
  min-height: 100%;
  background-color: @color-background;

  &_unAuth {
    background-image: url("@{path-img}bg/bg@1x.png");
    background: -webkit-image-set(url('@{path-img}bg/bg@1x.png') 1x, url('@{path-img}bg/bg@2x.png') 2x, url('@{path-img}bg/bg@3x.png') 3x);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__header {
    .debug(false, #2117cc);
    .transition(0.3s);
    .size(100%, auto);
    z-index: @z-index-header;
  }

  &__content {
    .debug(false);
    .size(100%, auto);
    flex: 1 0 auto;
    padding-bottom: 20px;
    display: flex;
  }

  &__footer {
    .debug(false, #0c0);
    .size(100%, auto);
    flex: 0 0 auto;
  }
}

.content {
  width: 100%;
  max-width: @max-window-size + @gap-desktop * 2;
  margin-left: auto;
  margin-right: auto;

  padding-left: @gap-mobile;
  padding-right: @gap-mobile;

  @media @screen-tablet {
    padding-left: @gap-tablet;
    padding-right: @gap-tablet;
  }

  @media @screen-desktop {
    padding-left: @gap-desktop;
    padding-right: @gap-desktop;
  }

  &_no-padding {
    max-width: @max-window-size;
    padding-left: 0;
    padding-right: 0;
  }

  &_no-padding-touch,
  &_no-padding-mobile {
    @media @screen-mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_no-padding-touch,
  &_no-padding-tablet {
    @media @screen-tablet-only {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_no-padding-desktop {
    @media @screen-desktop {
      max-width: @max-window-size;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>