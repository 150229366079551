<template>
  <div class="main-un-auth content">

    <Title type="h1" theme="huge" class="main-un-auth__title">{{ $t('main.unAuth.title') }}</Title>

    <p>
      {{ $t('main.unAuth.desc') }}
    </p>

    <Button theme="huge" @click="doReg">
      {{ $t('main.unAuth.register') }}
    </Button>

    <div class="main-un-auth__features">
      <IconedText>
        <template #icon>
          <draw-svg name="check" width="9" height="7"/>
        </template>
        {{ $t('main.unAuth.features.events') }}
      </IconedText>

      <IconedText>
        <template #icon>
          <draw-svg name="check" width="9" height="7"/>
        </template>
        {{ $t('main.unAuth.features.fastPayments') }}
      </IconedText>
    </div>


  </div>
</template>
<script lang="ts" setup>
// Store
import { useAuthModalStore } from '@/store'
const modalStore = useAuthModalStore()

// Components
import Button from '@/components/ui/Button'
import IconedText from '@/components/ui/IconedText'
import Title from '@/components/ui/Title'

// Methods
function doReg(): void {
  modalStore.openReg()
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.main-un-auth {
  .debug(false);
  justify-self: center;

  .flex(@direction: column);
  text-align: center;

  &__title {
    margin-top: 116px;
    max-width: 609px;
  }

  p {
    max-width: 497px;
    padding: 0;
    margin: 30px 0 50px;
    font-size: @text-size-medium;
  }

  &__features {
    .flex(@gap: 30px);
    margin-top: 30px;
  }
}
</style>