import InputDate from './InputDate.vue'
import type { HintType } from '@/store/task'
export type Themes = 'inTask'

export interface Props {
  theme?: Themes | Array<Themes>
  label?: string
  placeholder?: string
  name?: string
  modelValue?: any
  value?: string
  hint?: HintType
  format?: string | null
  valueFormat?: string
  error?: string | null | undefined
}

export default InputDate