import type { QueryType, IdType, DataType } from '@/common/types'

import { Http } from "@/network/http";
import ProfileApi from "@/network/components/profiles";
import MainApi from "@/network/components/main";
import TasksApi from "@/network/components/tasks";

export class Api {
  http: any;
  profile: any;
  main: any;
  tasks: any;

  constructor() {
    this.http = new Http("");
    this.profile = new ProfileApi(this.http);
    this.main = new MainApi(this.http);
    this.tasks = new TasksApi(this.http);
  }
}

export const api = new Api();
