<template>
  <div class="avatar" :class="[themeClassName]">
    <img v-if="picture?.length" :src="picture"/>
    <draw-svg v-else name="user"/>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Types
import { Props } from '@/components/ui/Avatar'

// Props
const props = defineProps<Props>()

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Computed
const themeClassName = computed(() => useThemeClassName('avatar', props.theme))
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.avatar {
  .size(var(--avatar-size, 40px), var(--avatar-size, 40px));
  .flex();
  border: var(--avatar-border, 1px solid @color-blue);
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(255,255,255, .4);
  color: @color-white;

  img,
  svg {
    .square(100%);
  }

  &_big {
    --avatar-size: 100px;
  }

  &_huge {
    --avatar-size: 150px;
  }

  img {
    .square(100%);
    object-fit: cover;
  }

}
</style>