<template>
  <div class="ui-input-date" :class="[themeClassName]" @click="clearError">
    <div v-if="text" class="ui-input-date__label">{{ text }}</div>
    <div class="ui-input-date__input">
      <el-date-picker
          @visible-change="clearError"
          type="date"
          :name="name"
          :format="format"
          :value-format="valueFormat"
          v-model="model"
      />
    </div>
    <div v-if="proxiedError" class="ui-input-date__error" data-error="true">
      {{ proxiedError }}
    </div>
    <HintText
        v-if="hint"
        :link-text="hint.link"
        :hint-text="hint.text"
        class="ui-input-date__hint"
    />
  </div>
</template>


<script lang="ts" setup>
import { computed, onMounted } from 'vue'

// Use
import { useVModel } from '@vueuse/core'
import useThemeClassName from '@/hooks/useThemeClassName'

// Components
import { ElDatePicker } from 'element-plus'
import HintText from '@/components/ui/HintText'

// Types
import type { Props } from './index'

// Props
const props = withDefaults(defineProps<Props>(), {
  format: 'DD.MM.YYYY',
  valueFormat: 'DD.MM.YYYY',
})


// Emits
const emit = defineEmits<{
  'update:modelValue': [string | null];
  'update:error': [boolean];
}>()

// Data
let model = useVModel(props, 'modelValue', emit)
const proxiedError = useVModel(props, 'error', emit)

// Computed
const themeClassName = computed(() => useThemeClassName('ui-input-date', props.theme))
const text = computed<string | null>(() => props.placeholder ?? props.label ?? null)

// Methods
function clearError(): void {
  proxiedError.value = null
}

onMounted(() => {
  if (props.value) {
    model.value = props.value
  }
})
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.ui-input-date {
  .debug(false);

  &__label {
    font-size: @text-size-tiny;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .el-input {
    --el-date-editor-width: 100%;
    --el-input-border: 1px solid @color-white;
    --el-input-hover-border-color: @color-blue;
    --el-input-focus-border-color: @color-blue;
    --el-input-bg-color: transparent;
    --el-input-border-radius: 10px;
    --el-input-text-color: @color-white;
    --el-input-icon-color: @color-white;
    --el-input-inner-height: 1;
    --el-input-height: 48px;
  }

  &_inTask {
    max-width: 300px;
  }

  &__hint {
    margin-top: 10px;
  }

  &__error {
    color: var(--input-error-text-color, @color-red);
    margin-top: var(--input-error-margin-top, 5px);
  }

}
</style>
