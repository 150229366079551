<template>
  <div class="input-file" @click="clearError">

    <div class="input-file__wrapper">
      <div class="input-file__first">

        <div class="input-file__title" v-if="number || title">
          <span class="input-file__number" v-if="number">{{ number }}</span>
          <span class="input-file__title-text" v-if="title">{{ title }}</span>
        </div>
        <div v-if="desc" class="input-file__desc">
          {{ desc }}
        </div>

        <div class="input-file__input">
          <div class="input-file__filename" v-if="fileToUpload">
            <span>{{ fileToUpload }}</span>

            <Button theme="icon" @click="reset">
              <draw-svg name="close" width="20" height="20"/>
            </Button>
          </div>

          <label
              class="input-file__label"
              :for="`input-${id}`"
              :class="{'input-file__label-draggable': draggable}"
              @dragstart="draggable = true"
              @dragover="draggable = true"
              @dragleave="draggable = false"
              @drop="draggable = false"
          >
            <draw-svg name="upload-photo" width="20" height="20"/>
            {{ placeholder }}


            <input ref="input" :id="`input-${id}`" type="file" @change="setValue" :accept="accept">
          </label>
          <div v-if="error.length" class="input-file__error">
            <div v-for="error in error">
              {{ error }}
            </div>
          </div>
        </div>

        <div v-if="proxiedError" class="input-file__error" data-error="true">
          {{ proxiedError }}
        </div>

      </div>
      <div class="input-file__middle" v-if="demo">
        <Button theme="demo" @click="tryToShowDemo(demo)">
          {{ $t('inputFile.demo') }}

          <template #append>
            <draw-svg name="eye" width="20" height="20"/>
          </template>
        </Button>
      </div>
      <div class="input-file__last">
        <img v-if="image" :src="image">
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { idGenerator, getExtension } from '@/common/helpers'

// Components
import Button from '@/components/ui/Button'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Types
import type { Props } from './index'
import DrawSvg from '@/common/DrawSvg/DrawSvg.vue'

// Props
const props = withDefaults(defineProps<Props>(), {
  accept: '.gif, .jpeg, .jpg, .png',
  maxFileSize: 26214400, // 25 MB
})

// Emits
const emit = defineEmits<{
  'update:modelValue': [any];
  'showDemo': [string];
  'update:error': [boolean];
}>()

// Data
let model = useVModel(props, 'modelValue', emit)
const proxiedError = useVModel(props, 'error', emit)
const draggable = ref<boolean>(false)
const id = idGenerator()
const input = ref()
const error = ref<Array<string>>([])
const fileToUpload = ref<any | null>(null)

// Computed
const image = computed<string | null>(() => {
  const file = (model.value) ? URL.createObjectURL(model.value) : null
  return file ?? props.value
})

// Methods
function clearError(): void {
  proxiedError.value = null
}

function setValue(event: Event): void {
  const target = event.target as HTMLInputElement
  let file = target.files?.[0] ?? null
  clearError();
  if (!file?.name) {
    return
  }

  const ext = getExtension(file!.name).toLowerCase() ?? null
  const isAllow = props.accept.includes(ext)
  error.value = []

  if (!isAllow) {
    error.value.push(t('inputFile.notAccept'))
  }

  if (file.size && file.size > props.maxFileSize) {
    error.value.push(t('inputFile.sizeLimit'))
  }


  if (!error.value.length) {
    fileToUpload.value = file?.name
    model.value = file
  } else {
    reset()
  }
}

function reset(): void {
  input.value.value = ''
  fileToUpload.value = null
  model.value = null
}

function tryToShowDemo(imageUrl: string): void {
  emit('showDemo', imageUrl)
}
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.input-file {

  &__wrapper {
    .flex(space-between, flex-end, @gap: 16px);
    position: relative;
  }

  &__first {
    position: relative;
    max-width: 460px;
  }

  &__input {
    margin-top: 30px;
  }

  &__label {
    .size(460px, auto);
    .flex(@direction: column, @gap: 10px);
    .transition;
    position: relative;
    aspect-ratio: 460 / 149;
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
    border-color: @color-grey;
    color: @color-grey;
    background-color: @color-blue-bg;

    &:hover {
      color: @color-white;
      border-color: @color-blue;
    }
  }

  &__error {
    .flex(@direction: column, @gap: 5px);
    position: absolute;
    bottom: 2px;
    width: calc(100% - 4px);
    left: 2px;
    right: 2px;
    color: @color-pink;
    pointer-events: none;
    padding: 10px;
    backdrop-filter: blur(10px);
    border-radius: 15px;
  }

  &__label-draggable {
    border-style: dashed;
    border-color: @color-blue;
  }

  input[type=file] {
    .square(100%);
    .absoluteFillSize;
    opacity: 0;
    cursor: pointer;
  }

  &__middle {
    .debug(false);
  }

  &__last {
    .debug(false);
    .size(120px, auto);
    .flex();
    aspect-ratio: 120/263;
    overflow: hidden;
    border-radius: 10px;

    img {
      .square(100%);
      object-fit: cover;
    }
  }

  &__filename {
    .flex(flex-start, @gap: 16px);
    position: absolute;
    top: calc(100% + 5px);
    left: 5px;
  }

  &__title {
    .flex(flex-start, flex-end, @gap: 15px);
    margin-bottom: 20px;
  }

  &__title-text {
    font-size: 24px;

  }

  &__number {
    color: @color-blue;
    font-size: @text-size-extra-largest;
    line-height: 1;
  }

  &__error {
    color: @color-red;
    margin-top: 5px;
  }

}
</style>