<template>
  <div class="task-card">
    <picture>
      <img v-if="item.img" :src="item.img.x1" :srcset="srcSet"/>
    </picture>

    <Badge class="task-card__badge" v-if="showBadge" :status="status"/>

    <div class="task-card__info">

      <div class="task-card__name">
        <div v-if="item.id" class="task-card__nano">
          #{{ item.id }}
        </div>
        {{ item.name }}
      </div>

      <div>
        <div class="task-card__nano">{{ priceText }}:</div>
        <Money class="task-card__price" :money="item.price"/>
      </div>

      <Conditions :conditions="item.conditions"/>

      <Button @click="gotoTask(item.id)">{{ buttonText }}</Button>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import router from '@/router'
import { isNumber } from '@/common/helpers'

// Types
import { Props } from '@/components/TaskCard'

// Components
import Button from '@/components/ui/Button'
import IconedText from '@/components/ui/IconedText'
import Money from '@/components/ui/Money'
import Badge from '@/components/ui/Badge'
import Conditions from '@/components/Conditions'

// Props
const props = defineProps<Props>()

// i18n
import { useI18n } from 'vue-i18n'
import { IdType } from '@/common/types'
import { StatusType } from '@/components/TaskSlider'
const { t } = useI18n()

// Computed
const srcSet = computed<string | null>(() => {
  if (!props.item.img) {
    return null
  }
  let result = `${props.item.img.x1} 1x`
  if (props.item.img.x2) {
    result += `, ${props.item.img.x2} 2x`
  }
  if (props.item.img.x3) {
    result += `, ${props.item.img.x3} 3x`
  }
  return result
})

const status = computed<StatusType>(() => props.item?.status ?? 'new')

const buttonText = computed<string | null>(() => {
  if (!Boolean(['awaitReview', 'error', 'new'].includes(status.value))) {
    return t('taskCard.button.default')
  }
  return t('taskCard.button.' + status.value)
})
const showBadge = computed<boolean>(() => Boolean(props.item.status && status.value !== 'new'))

const priceText = computed<string>(() => props.item.price_text?.length ? props.item.price_text : t('taskCard.price'))

function gotoTask(id: IdType): void {
  router.push({name: 'Task', params: {id}})
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.task-card {
  .debug(false);
  width: 100%;
  aspect-ratio: 260/350;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  picture {
    .absoluteFillSize;
    .flex();
    z-index: 1;

    img {
      object-fit: contain;
    }

    &::before {
      .absoluteFillSize;
      content: '';
      z-index: 2;
      background: linear-gradient(0deg, #130E26 0%, rgba(61, 43, 131, 0.97) 33.6%, rgba(38, 27, 78, 0.00) 100%);
    }
  }

  &__info {
    .absoluteFillSize(20px, @top: 70px);
    .flex(flex-end, flex-start, column, @gap: 15px);
    z-index: 10;
  }

  &__nano {
    font-size: @text-size-small;
    margin-bottom: 5px;
  }

  &__price {
    font-size: @text-size-medium;
    font-family: @font-semibold;
  }

  &__name {
    font-size: @text-size-medium;
    font-family: @font-medium;
  }

  &__badge {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
  }
}
</style>