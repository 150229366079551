import Money from './Money.vue'

export interface Props {
  tag?: string
  money: number | string
  currency?: string
  addZeros?: boolean
  separator?: ',' | '.'
  theme?: string | [] | null
}

export default Money
