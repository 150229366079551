<template>
  <div class="iconed-text" :class="[themeClassName]">
    <slot name="icon"></slot>
    <span><slot/></span>
    <slot name="iconAppend"></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Types
import type { Props } from '@/components/ui/IconedText'

// Props
const props = defineProps<Props>()

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Computed
const themeClassName = computed(() => useThemeClassName('iconed-text', props.theme))

</script>
<style lang="less">
@import "@/common/less/dependence.less";

.iconed-text {
  .debug(false);
  .flex(var(--iconed-text-align, flex-start), center, @direction: row, @gap: 10px);

  font-size: var(--iconed-text-size, @text-size-small);
  font-weight: var(--iconed-text-font-weight, 400);

  &_big {
    --iconed-text-size: @text-size-tiny;
  }

  &_font-default {
    --iconed-text-size: @text-size-default;
  }

  &_link {
    .animatedUnderline();
    position: relative;
    display: inline-flex;
    --iconed-text-font-weight: 700;
  }

  &_center {
    --iconed-text-align: center;
  }
}
</style>