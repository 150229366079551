<template>
  <div class="badge" :class="[`badge_${status}`]">
    {{ $t(`badge.${status}`) }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Types
import { Props } from '@/components/ui/Badge'

// Props
const props = defineProps<Props>()

// i18n
import { useI18n } from 'vue-i18n'

const { t } = useI18n()


</script>

<style lang="less">
@import "@/common/less/dependence.less";

.badge {
  .debug(false);
  border-radius: 10px;
  padding: 10px 20px;
  backdrop-filter: blur(7.5px);
  background: rgba(180, 180, 180, .6);

  &_awaitPayment {
    background: rgba(114, 78, 255, .6);
  }

  &_awaitReview {
    background: rgba(3, 3, 3, .6);
  }

  &_done {
    background: rgba(0, 255, 0, .6);
  }

  &_error {
    background: rgba(246, 0, 0, .6);
  }

}
</style>