<template>
  <div class="ui-input-text" :class="[themeClassName]" @click="clearError">
    <div v-if="text" class="ui-input-text__label">{{ text }}</div>
    <div class="ui-input-text__input">
      <input v-if="useMask" v-focus="focus" type="text" :name="name" v-model="model" v-mask="MASKS[mask]">
      <input v-else type="text" v-focus="focus" :name="name" v-model="model">
    </div>
    <div v-if="proxiedError" class="ui-input-text__error" data-error="true">
      {{ proxiedError }}
    </div>
    <HintText
        v-if="hint"
        :link-text="hint.link"
        :hint-text="hint.text"
        class="ui-input-text__hint"
    />
  </div>
</template>


<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { MASKS } from '@/common/mask'
import { mask as vMask } from 'vue-the-mask'

// Use
import { useVModel } from '@vueuse/core'
import useThemeClassName from '@/hooks/useThemeClassName'

// Components
import HintText from '@/components/ui/HintText'

// Types
import type { Props } from './index'

// Props
const props = withDefaults(defineProps<Props>(), {})

// Emits
const emit = defineEmits<{
  'update:modelValue': [string | null];
  'update:error': [boolean];
}>()

// Data
let model = useVModel(props, 'modelValue', emit)
const proxiedError = useVModel(props, 'error', emit)


// Computed
const themeClassName = computed(() => useThemeClassName('ui-input-text', props.theme))
const text = computed<string | null>(() => props.placeholder ?? props.label ?? null)
const useMask = computed<boolean>(() => Boolean(props.mask && MASKS?.[props.mask]))

// Methods
function clearError(): void {
  proxiedError.value = null
}

onMounted(() => {
  if (props.value) {
    model.value = props.value
  }
})
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.ui-input-text {
  .debug(false);

  &__label {
    font-size: @text-size-tiny;
    font-weight: 500;
    margin-bottom: 10px;
  }

  input {
    .transition();
    width: var(--input-text-width, 100%);
    max-width: var(--input-text-max-width, initial);
    padding: var(--input-text-padding, 15px 20px);
    color: var(--input-text-color, @color-white);
    border-radius: var(--input-text-border-radius, 10px);
    border: var(--input-text-border, 1px solid @color-white);
    background-color: var(--input-text-background-color, transparent);
    letter-spacing: var(--input-text-letter-spacing, .5px);
    outline: none;
  }

  input:active,
  input:focus {
    --input-text-border: 1px solid @color-blue;
  }

  &__input {
    position: var(--input-text-position, relative);

    &::before {
      content: var(--input-text-before-content);
      position: absolute;
      display: block;
      top: var(--input-text-before-top, 14px);
      left: var(--input-text-before-left, 14px);
      color: var(--input-text-before-color, @color-white);
      pointer-events: none;
    }
  }

  &_tg {
    --input-text-position: relative;
    --input-text-before-content: '@';
    --input-text-padding: 15px 20px 15px 30px;
  }

  &_inTask {
    --input-text-max-width: 300px;
  }

  &__hint {
    margin-top: 10px;
  }

  &__error {
    color: var(--input-error-text-color, @color-red);
    margin-top: var(--input-error-margin-top, 5px);
  }
}
</style>
