/* eslint-disable @typescript-eslint/no-unused-vars */
import { endpoints } from '@/network/endpoints'
import type { AxiosPromise } from 'axios'
import type { QueryType, IdType, DataType } from '@/common/types'
import * as mock from '@/mocks/common'
import * as taskMock from '@/mocks/task'
import type * as commonType from '@/store/common'

export default class TasksApi {
  http: any

  constructor(http: any) {
    this.http = http
  }

  async getTasks(): Promise<AxiosPromise> {
    return await this.http.get(endpoints.tasks.getTasks)
  }

  async getTask(id: IdType): Promise<AxiosPromise> {
    return (await this.http.get(endpoints.tasks.getTask(id))).data
  }

  async startTask(id: IdType): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.tasks.startTask, { id_task: id }))
  }

  async saveStep(data: DataType): Promise<AxiosPromise> {
    return (await this.http.post(endpoints.tasks.saveStep, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }))
  }

  // Mock
  // async saveStep(id: IdType, data: any): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       // resolve(true)
  //       reject('some error')
  //     }, 2000)
  //   })
  // }
  // async getTask(id: IdType): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       resolve(taskMock.task)
  //       // reject('some error');
  //     }, 1000)
  //   })
  // }
}
