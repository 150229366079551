import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { createApp, Directive } from 'vue'
import { createPinia } from 'pinia'
import 'dayjs/locale/ru'

// @ts-ignore TS ругается, что подключается не православный JS файл в божественный TS
import ru from 'element-plus/dist/locale/ru.js'

import DrawSvg from '@/common/DrawSvg'
import i18n from '@/locales/i18n'

createApp(App)
  .use(router)
  .use(ElementPlus, { locale: ru })
  .use(i18n)
  .use(createPinia())
  .component('DrawSvg', DrawSvg)
  .directive('click-outside', <Directive>{
    mounted(element, { value }) {
      element.clickOutside = (event: Event) => {
        if (!(element === event.target || element.contains(event.target))) {
          value(event)
        }
      }
      document.body.addEventListener('click', element.clickOutside)
    },
    unmounted(element) {
      document.body.removeEventListener('click', element.clickOutside)
    }
  })
  .directive('focus', <Directive>{
    mounted(el, { value }) {
      if (value) {
        el.focus()
      }
    }
  })
  .mount('#app')
