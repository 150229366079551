import { defineStore } from 'pinia'
import { api } from '@/network'
import { DataType, IdType } from '@/common/types'

// TS
export type DisplayType = 'name' | 'card'

export interface ProfileStateType {
  modal: {
    show: boolean
    loading: boolean
    display: null | DisplayType
  }
}

// Store
export const useProfileStore = defineStore({
  id: 'profile',

  state: () =>
    <ProfileStateType>{
      modal: {
        show: false,
        loading: false,
        display: null,
      },
    },

  actions: {
    changeName(data: DataType): Promise<any> {
      return api.profile.changeName(data)
    },

    uploadAvatar(data: DataType): Promise<any> {
      return api.profile.uploadAvatar(data)
    },

    addNewCard(data: DataType): Promise<any> {
      return api.profile.addNewCard(data)
    },

    deleteCard(id: IdType): Promise<any> {
      return api.profile.deleteCard(id)
    },

    setDefaultCard(id: IdType): Promise<any> {
      return api.profile.setDefaultCard(id)
    },


  },

})
