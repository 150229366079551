<template>
  <component :is="tag" class="ui-money" :class="[themeClassName]" v-html="price"/>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { formatMoney } from '@/common/helpers'

// Types
import type { Props } from '@/components/ui/Money'

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Props
const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  money: 0,
  addZeros: false,
  separator: ',',
  currency: '₽',
})

// Computed
const themeClassName = computed(() => useThemeClassName('ui-money', props.theme))
const price = computed<string>(() => {
  return `${formatMoney(Number(props.money), props.addZeros, props.separator,)} ${props.currency}`
})
</script>