export default {
  title: 'Редактировать профиль',
  name: 'Имя',
  tg: 'Телеграм',
  edit: 'Редактировать',
  changeNameTitle: 'Сменить имя',
  newName: 'Новое имя',
  extError: 'Картинки такого формата не поддерживаются',
  maxSizeError: 'Картинки слишком много весит, можно загружать не более 25мб',
  addCardTitle: 'Добавить новую карту',
  newCard: 'Новая карта',
  submit: 'Подтвердить',
}