<template>
  <div class="context-menu-item">
    <slot/>
  </div>
</template>

<style lang="less">
@import "/src/common/less/dependence";

.context-menu-item {
  .debug(false);
  .transition();
  color: @color-white;
  padding: 5px 20px;
  white-space: nowrap;
  font-weight: 500;
  font-size: @text-size-small;
  line-height: 1.83;

  &:hover {
    background-color: @color-pink;
  }
}
</style>