<template>
  <div class="welcome">

    <div class="welcome__title">
      <Title type="h2" theme="big">
        {{ $t('hi') }},<br>
        {{ commonStore.user.name }}!
      </Title>

      <div class="welcome__text">
        {{ $t('welcome') }}
      </div>
    </div>

    <div class="welcome__avatar">
      <Avatar :picture="commonStore.user?.avatar" theme="big"></Avatar>

      <Button type="router-link" :to="{name: 'Profile'}" theme="icon">
        <draw-svg name="settings" width="30" height="30"/>
      </Button>
    </div>

    <EarnedCard class="welcome__card"/>
  </div>
</template>

<script lang="ts" setup>
// Components
import Title from '@/components/ui/Title'
import Avatar from '@/components/ui/Avatar'
import Button from '@/components/ui/Button'
import EarnedCard from '@/components/EarnedCard'

// Store
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.welcome {
  .debug(false);

  .flex(flex-start, flex-start, @gap: 60px);

  &__title {
    flex: 1 0 auto;
  }

  &__text {
    margin-top: 20px;
    max-width: 301px;
    font-size: @text-size-medium;
  }

  &__avatar {
    .flex(flex-start, flex-start, @gap: 10px);
  }
}
</style>