<template>
  <div class="you-tube-video">
    <div class="you-tube-video__icon">
      <draw-svg name="play-button"/>
    </div>
    <img :src="picture">
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Types
import type { Props } from './index'

// Props
const props = defineProps<Props>()

// Computed
const id = computed<string | null>(() => youtubeParser(props.link))
const picture = computed<string | null>(() => (id) ? `//img.youtube.com/vi/${id.value}/0.jpg` : null)

// Methods
function youtubeParser(url: string): string | null {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return (match && match[7].length == 11) ? match[7] : null
}
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.you-tube-video {
  .debug(false);
  .flex();
  aspect-ratio: 360 / 235;
  max-width: 360px;
  position: relative;
  overflow: hidden;
  border: 1px solid @color-blue;
  border-radius: 15px;
  cursor: pointer;

  &__icon {
    .center();
    .square(80px);
    color: @color-blue;

    svg {
      .square(100%);
      .scale(1, 1);
      transition: .3s;
      pointer-events: none;
    }
  }

  &:hover &__icon svg {
    .scale(1.2, 1.2);
  }

  img {
    object-fit: cover;
  }

}
</style>