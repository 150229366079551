import { defineStore } from 'pinia'
import { api } from '@/network'
import type { DataType, IdType, notRequiredText, QueryType, TaskPoleTypes } from '@/common/types'
import type { ConditionsType } from '@/components/Conditions'
import type { maskNames } from '@/common/mask'
import router from '@/router'

export interface HintType {
  link?: notRequiredText,
  text: string,
}

export interface CommonProps {
  required: boolean
  placeholder: notRequiredText
  value: notRequiredText
}

export interface StringProps extends CommonProps {
  hint?: HintType
  mask?: maskNames
}

export interface DateProps extends CommonProps {
  hint?: HintType | null
}

export interface CardProps extends CommonProps {
}

export interface FileProps extends CommonProps {
  number: notRequiredText
  title: notRequiredText
  desc: notRequiredText
  demo: notRequiredText
}

export interface StepType {
  id_step: IdType
  active: boolean
  name: string
  poles: Array<StepPolesType>
}

export interface StepPolesType {
  id: IdType
  type: TaskPoleTypes
  props: FileProps | StringProps | DateProps | CardProps
}

export interface TaskInfoType {
  id: IdType
  name: string
  conditions: ConditionsType
  video: string | null
  priceText: string | null
  price: number
  link: string | null
  img: null | {
    x1?: string
    x2?: string
    x3?: string
  }
}

export interface TaskType {
  info: TaskInfoType
  steps: Array<StepType>
}

export interface TaskStateType {
  loading: boolean
  init: boolean
  error: boolean
  task: TaskType | null
}

export const useTaskStore = defineStore({
  id: 'task',

  state: () =>
    <TaskStateType>{
      loading: false,
      init: false,
      error: false,
      task: null,
    },

  actions: {

    getTask(id: IdType): Promise<TaskType> {
      return api.tasks.getTask(id)
    },

    startTask(id: IdType) {
      return api.tasks.startTask(id)
    },

    saveStep(data: DataType) {
      return api.tasks.saveStep(data)
    },

    async prevStep() {
      if (this.screen === 'main') {
        return await router.push({ name: 'Main' })
      }
      const currentScreen: StepType = this.task!.steps[Number(this.screen)]
      const prevScreen: StepType | null = (!this.isFirstStep) ? this.task!.steps[Number(this.screen) - 1] : null
      if (prevScreen) {
        prevScreen.active = true
      }
      currentScreen.active = false
    },

    async nextStep() {
      let nextScreen: StepType | null = null
      let currentScreen: StepType | null = null

      if (this.isLastScreen) {
        return await router.push({ name: 'Main' })
      }

      if (this.isMainScreen) {
        nextScreen = this.task!.steps[0]
      }
      if (!this.isMainScreen) {
        currentScreen = this.task!.steps[Number(this.screen)]
      }
      if (!this.isMainScreen && !this.isLastScreen) {
        nextScreen = this.task!.steps[Number(this.screen) + 1]
      }
      if (currentScreen) {
        currentScreen.active = false
      }
      if (nextScreen) {
        nextScreen.active = true
      }
    },
  },

  getters: {
    screen(): string | number {
      let result: string | number = 'main'
      if (this.task?.steps) {
        const activeIndex = this.task.steps.findIndex(item => item.active)
        if (activeIndex >= 0) {
          result = activeIndex
        }
      }
      return result
    },

    activeScreen(): null | StepType {
      if (this.screen === 'main') {
        return null
      }
      return this.task?.steps[Number(this.screen)] as StepType
    },

    isLastScreen(): boolean {
      return Boolean(this.screen === Number(this.task?.steps.length) - 1)
    },

    isFirstStep(): boolean {
      return Boolean(this.screen === 0)
    },

    isMainScreen(): boolean {
      return Boolean(this.screen === 'main')
    },

    countScreens(): number {
      return this.task?.steps.length ?? 0
    },
  },
})
