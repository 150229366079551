import HintText from './HintText.vue'

export type Themes = ''

export interface Props {
  linkText: string
  hintText: string
  tag?: 'span' | 'a' | 'div' | 'h1' | 'h2' | 'h3' | 'router-link'
  theme?: Themes | Array<Themes>
  to?: {} | null | string
  placement?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end'
  effect?: 'light' | 'dark'
}

export default HintText