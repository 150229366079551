<template>
  <component :is="type" class="ui-button" v-bind="$attrs" :to="to" :class="[themeClassName]">
    <slot name="prepend"/>
    <slot/>
    <slot name="append"/>
  </component>
</template>


<script lang="ts" setup>
import { computed } from 'vue'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Types
import type { Props } from '@/components/ui/Button'

// Props
const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  to: null,
})

// Computed
const themeClassName = computed(() => useThemeClassName('ui-button', props.theme))
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.ui-button {
  .debug(false);
  .flex(@display: inline-flex, @gap: var(--button-gap, 10px));
  .size(var(--button-width, auto), var(--button-height, auto));
  .scale(var(--button-scale, 1), var(--button-scale, 1));

  background: var(--button-bg, linear-gradient(@gradient-pink));
  background-color: var(--button-bg-color, initial);
  box-shadow: var(--button-box-shadow, 0px 10px 40px 0px rgba(114, 78, 255, 0.50));

  min-width: var(--button-min-width, initial);
  border-radius: var(--button-border-radius, 10px);
  padding: var(--button-padding, 10px 20px);
  border: var(--button-border, 0);
  opacity: var(--button-opacity, 1);
  will-change: background-color, color, opacity;
  transition: var(--button-transition, @transition-default);
  outline: none;
  user-select: none;
  cursor: var(--button-cursor, pointer);

  color: var(--button-text-color, @color-white);
  line-height: var(--button-line-height, 1.1);
  font-family: var(--button-font-family, @font-default);
  font-size: var(--button-font-size, @text-size-tiny);
  font-weight: var(--button-font-weight, 400);
  white-space: var(--button-white-space, initial);
  text-decoration: var(--button-text-decoration, none);

  &:hover:not(:disabled) {
    --button-text-color: @color-white;
    --button-scale: var(--button-scale-hover, 1.08);
  }

  &:disabled {
    filter: grayscale(1);
    --button-cursor: default;
  }

  &_login {
    --button-font-size: @text-size-small;
    --button-bg: transparent;
    --button-border: 1px solid @color-blue;
    --button-box-shadow: none;
  }

  &_tg {
    --button-bg: transparent;
    --button-bg-color: #1599DA;
    --button-box-shadow: none;
  }

  &_huge {
    --button-padding: 20px 60px
  }

  &_icon {
    --button-padding: 0;
    --button-bg: transparent;
  }

  &_icon-padding {
    --button-padding: 10px;
    --button-bg: transparent;
    --button-box-shadow: none;
    --button-bg-color: rgba(114, 78, 255, 0.10);
    --button-scale-hover: 1.5;
  }

  &_stretched {
    --button-width: 100%;
  }

  &_black {
    --button-bg: linear-gradient(90deg, #313131 0%, #000 100%);
    --button-box-shadow: none;
  }

  &_task-control {
    --button-padding: 20px 40px;
    --button-white-space: nowrap;
    --button-font-weight: 500;
  }

  &_demo {
    --button-font-weight: 700;
    --button-padding: 20px 40px;
    --button-bg: none;
    --button-bg-color: @color-blue;
    --button-box-shadow: none;
  }
}
</style>
