/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeParams } from '@/common/helpers'
import type { QueryType, IdType } from '@/common/types'

export const endpoints = {
  profile: {
    getUser: '/api/user/current',
    registration: '/api/user/confirmRegister',
    sendAuthCode: '/api/user/sendAuthCode',
    checkAuthCode: '/api/user/checkAuthCode',
    changeName: '/api/user/current/setName',
    uploadAvatar: '/api/user/current/setAvatar',
    addNewCard: '/api/user/current/saveNewCard',
    deleteCard: (id: IdType) => `/api/user/current/deleteCard/${id}`,
    setDefaultCard: (id: IdType) => `/api/user/current/setDefaultCard/${id}`,
  },


  tasks: {
    getTasks: `/api/task/current_user`,
    getTask: (id: IdType) => `/api/task_link/get_by_task_id?id_task=${id}`,
    startTask: `/api/task_link/link_to_current_user`,
    saveStep: `/api/task_link/save_data`,
  },

}
