import InputText from './InputText.vue'
import type { HintType } from '@/store/task'
export type Themes = 'tg' | 'inTask'
import type { maskNames } from '@/common/mask'

export interface Props {
  theme?: Themes | Array<Themes>
  label?: string
  focus?: boolean
  placeholder?: string
  name?: string
  modelValue?: any
  value?: string | number
  hint?: HintType
  mask?: maskNames
  error?: string | null | undefined
}

export default InputText