import Conditions from './Conditions.vue'

export type ConditionsType = 'full' | number
export type Themes = 'big'

export interface Props {
  theme?: Themes | Array<Themes>
  conditions: ConditionsType
}

export default Conditions