<template>
  <Component :is="activeGroup">
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { computed, type Component } from "vue";
import { useRoute } from "vue-router";

import Main from "@/layouts/Main";
import UnAuth from "@/layouts/unAuth";

const route = useRoute();

interface PossibleComponents {
  [index: string]: Component;
}

const layouts: PossibleComponents = {
  Main,
  UnAuth
};

const activeGroup = computed(() => {
  const componentName = route.meta?.layout as string ?? "Main";
  return layouts[componentName];
});

</script>

