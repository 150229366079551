<template>

  <template v-if="!commonStore.user?.cards.length">
    <InputText v-model="model" v-bind="props" :model-error="proxiedError" theme="inTask"/>
  </template>

  <div class="ui-input-card" v-else @click="clearError">

    <el-radio-group v-model="card">

      <el-radio v-for="card in commonStore.user.cards" :label="card.id">

        <div class="ui-input-card__item">
          <draw-svg v-if="card.paymentSystem" :name="`${card.paymentSystem}-color`" width="25" height="25"/>
          <span>{{ card.number }}</span>
        </div>

      </el-radio>

      <el-radio label="new">

        <InputText
            v-if="display"
            v-model="model"
            v-bind="props"
            placeholder=""
            theme="inTask"
            :error="null"
            focus
        />

        <template v-else>
          {{ $t('profile.newCard') }}
        </template>

      </el-radio>
    </el-radio-group>

    <div v-if="proxiedError" class="ui-input-card__error" data-error="true">
      {{ proxiedError }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { isNumber } from '@/common/helpers'

// Use
import { useVModel } from '@vueuse/core'

// Store
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()

// Components
import InputText from '@/components/inputs/InputText'

// Types
import type { Props } from './index'
import { IdType } from '@/common/types'

// Props
const props = withDefaults(defineProps<Props>(), {})

// Emits
const emit = defineEmits<{
  'update:modelValue': [string | null];
  'update:error': [boolean];
}>()

// Data
let model = useVModel(props, 'modelValue', emit)
const proxiedError = useVModel(props, 'error', emit)
const card = ref<string | number>(props.value ?? '')
const display = ref<boolean>(Boolean(card.value === 'new'))

// Computed
const text = computed<string | null>(() => props.placeholder ?? props.label ?? null)


// Methods
function clearError(): void {
  proxiedError.value = null
}

function checkValue(id: IdType): void {
  const card = commonStore.user?.cards?.find(item => item.id === id)
  if (!card) {
    model.value = ''
  }
}

function setActive() {
  if (typeof model.value != 'number') {
    const active = commonStore.user?.cards?.find(item => item.active)
    card.value = active?.id ?? ''
  }
}

onMounted(() => {
  if (props.value) {
    model.value = props.value
    checkValue(props.value)
    nextTick(() => {
      setActive()
    })
  }
})


watch(card, (val) => {
  if (val === 'new') {
    display.value = true
    nextTick(() => {
      model.value = ''
    })
  } else {
    display.value = false
    model.value = val
  }
})
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.ui-input-card {
  .debug(false);

  &__item {
    .debug(false);
    .flex(@gap: 10px);
  }

  &__hint {
    margin-top: 10px;
  }

  &__error {
    color: var(--input-error-text-color, @color-red);
    margin-top: 15px;
  }
}
</style>
