<template>
  <div class="progress-line">
    <div v-for="cell in taskStore.countScreens"
         class="progress-line__cell"
         :class="{'progress-line__cell_active': cell === taskStore.screen + 1}"
    ></div>
  </div>
</template>

<script lang="ts" setup>

// Store
import { useTaskStore } from '@/store'
const taskStore = useTaskStore()

</script>

<style lang="less">
@import "@/common/less/dependence.less";

.progress-line {
  .debug(false);
  .size(100%, 6px);
  background-color: @color-white;
  border-radius: 5px;
  display: table;

  &__cell {
    .debug(false);
    display: table-cell;
    border-radius: inherit;

    &_active {
      background-color: @color-blue;
    }
  }
}
</style>