import InputFile from './InputFile.vue'
import type { FileProps } from '@/store/task'

export interface Props extends FileProps {
  modelValue: any;
  accept?: string
  maxFileSize?: number
  disabled?: boolean
  error?: string | null | undefined
}

export default InputFile
