<template>
  <svg
    :style="{ minWidth: styleWidth, width: styleWidth, height: styleHeight }"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :xlink:href="iconPath"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  name: string;
  width?: number | string;
  height?: number | string;
}

const props = defineProps<Props>();
const iconPath = computed(() => `/images/sprite.svg#${props.name}`);
const styleWidth = computed(() => (props.width ? `${props.width}px` : ""));
const styleHeight = computed(() => (props.height ? `${props.height}px` : ""));
</script>
