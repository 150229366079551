/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCommonStore } from '@/store'
import { NavigationGuardNext } from 'vue-router'

export default async function needAuth({ next }: { next: NavigationGuardNext }) {

  const commonStore = useCommonStore()
  const token = localStorage.getItem('token')

  if (!token) {
    commonStore.init = true
    return next()
  }

  if (!commonStore.user) {
    try {
      const user = await commonStore.getUser()
      commonStore.user = user
      return next()
    } catch (e: any) {
      next()
    } finally {
      commonStore.init = true
    }
  } else {
    next()
  }
}
