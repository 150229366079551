<template>
  <div class="task-step">
    <ProgressLine class="task-step__progress-line"/>

    <h1 class="task-step__title">{{ taskStore.activeScreen.name }}</h1>

    <div class="task-step__form">
      <div
          v-for="(item, index) in taskStore.activeScreen.poles"
          :key="`file-input-${item.id}-${index}`"
          class="task-step__form-item"
      >
        <component
            :is="getComponent(item.type)"
            :key="`component-${item.id}-${index}`"
            v-bind="item.props"
            theme="inTask"
            v-model="formData[item.id]"
            v-model:error="error[item.id]"
            @showDemo="tryShowDemo"
        />

        <template v-if="!getComponent(item.type)">
          {{ item }}
        </template>
      </div>

    </div>

    <el-dialog :lock-scroll="false" v-model="dialogVisible">
      <img style="width: 100%;" :src="dialogImageUrl"/>
    </el-dialog>

    <!--    <pre>modelValue - {{ formData }}-->
    <!--    errors - {{ error }}-->
    <!--    </pre>-->

    <TaskControls @nextStep="next" @prevStep="prev"/>

  </div>
</template>


<script lang="ts" setup>
import { ref, watch } from 'vue'
import { cloneProxy, getAxiosErrorText, idGenerator } from '@/common/helpers'
import { useScrollLock } from '@vueuse/core'

// Components
import TaskControls from '@/components/TaskControls'
import ProgressLine from '@/components/ProgressLine'
import InputFile from '@/components/inputs/InputFile'
import InputText from '@/components/inputs/InputText'
import InputDate from '@/components/inputs/InputDate'
import InputCard from '@/components/inputs/InputCard'
import { ElDialog, ElNotification } from 'element-plus'

// Router
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const id = route.params.id.toString()

// Types
import type { Component } from 'vue'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
import { useTaskStore, useCommonStore } from '@/store'
import { i18n } from '@/locales/i18n'
const taskStore = useTaskStore()
const commonStore = useCommonStore()

// Methods
function getComponent(name: string): Component | void {
  switch (name) {
    case 'file':
      return InputFile
    case 'string':
      return InputText
    case 'date':
      return InputDate
    case 'card':
      return InputCard
  }
}

function tryShowDemo(imgUrl: string): void {
  dialogImageUrl.value = imgUrl
  dialogVisible.value = true
}

// Data
const formData = ref<any>({})
const error = ref<any>({})
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const body = ref<HTMLElement | null>(document.body)
const isLocked = useScrollLock(body)

// Methods
function clear(): void {
  formData.value = {}
  error.value = {}
}

function validate(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    error.value = {}
    let hasErrors = false

    taskStore.activeScreen?.poles.forEach(item => {

      if (item.type === 'file') {
        if (item.props.required && !item.props.value && !formData.value[item.id]) {
          hasErrors = true
          error.value[item.id] = t('validate.required')
        }
      }

      if (item.type === 'string') {
        if (item.props.required && !formData.value[item.id]) {
          hasErrors = true
          error.value[item.id] = t('validate.required')
        }

        if (item.props.required && formData.value[item.id].length < 19 && !error.value[item.id] && item.props?.mask === 'card') {
          hasErrors = true
          error.value[item.id] = t('validate.card')
        }
      }

      if (item.type === 'date') {
        if (item.props.required && !formData.value[item.id]) {
          hasErrors = true
          error.value[item.id] = t('validate.required')
        }
      }

      if (item.type === 'card') {
        const type = (typeof formData.value[item.id] === 'number') ? 'number' : 'string'
        if (item.props.required) {
          if (type === 'string' && formData.value[item.id].length > 0 && formData.value[item.id].length < 19) {
            hasErrors = true
            error.value[item.id] = t('validate.card')
          }
          if (type === 'string' && formData.value[item.id].length < 1) {
            hasErrors = true
            error.value[item.id] = t('validate.required')
          }
        } else {
          if (type === 'string' && formData.value[item.id].length > 0 && formData.value[item.id].length < 19) {
            hasErrors = true
            error.value[item.id] = t('validate.required')
          }
        }
      }
    })

    if (hasErrors) {
      reject()
    } else {
      resolve(true)
    }
  })
}

async function next(): Promise<void> {
  try {
    await validate()
  } catch (e) {
    return
  }
  try {
    commonStore.loading = true
    const data = {
      id_task: id,
      id_step: taskStore.activeScreen?.id_step,
      poles: cloneProxy(formData.value),
    }
    await taskStore.saveStep(data)
    commonStore.user = await commonStore.getUser()

    if (taskStore.isLastScreen) {
      await router.push({ name: 'Main' })
    } else {
      taskStore.task = await taskStore.getTask(id)
    }
    clear()
  } catch (e: any) {
    ElNotification({
      title: i18n.t('common.error'),
      message: getAxiosErrorText(e),
      type: 'error',
    })
  } finally {
    commonStore.loading = false
  }
}

function prev(): void {
  clear()
  taskStore.prevStep()
}

// Watch
watch(dialogVisible, (val) => {
  isLocked.value = val
})
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.task-step {
  .debug(false);
  padding-top: 50px;

  &__progress-line {
    margin-bottom: 30px;
  }

  &__title {
    font-size: @text-size-largest;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 50px;
  }

  &__form {
    .flex(flex-start, flex-start, @direction: column, @gap: 50px);
    margin-bottom: 50px;
  }

  &__form-item {
    width: 100%;
  }
}
</style>