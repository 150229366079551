import { defineStore } from 'pinia'

export interface VideoStateType {
  link: null | string
}

export const useVideoStore = defineStore({
  id: 'video',

  state: () =>
    <VideoStateType>{
      link: null,
    },

  actions: {
    openVideo(link: string): void {
      this.link = link
    },

    closeVideo(): void {
      this.link = null
    },
  },

  getters: {
    type(): null | 'youtube' | 'vimeo' {
      if (!this.link) {
        return null
      }
      if (this.link.indexOf('vimeo') !== -1) {
        return 'vimeo'
      }
      if (this.link.indexOf('youtu') !== -1) {
        return 'youtube'
      }
      return null
    },

    id(): null | string {
      if (!this.link) {
        return null
      }

      if (this.type === 'youtube') {
        const re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig
        return this.link.replace(re, '$1')
      }

      if (this.type === 'vimeo') {
        if (!this.link) {
          return null
        }
        const regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/
        const match = this.link.match(regEx)
        if (match) {
          return match[6]
        }
      }

      return null
    },

    frameUrl(): null | string {
      if (this.type === 'youtube') {
        return `https://youtube-nocookie.com/embed/${this.id}?autoplay=1`
      }

      if (this.type === 'vimeo') {
        return `https://player.vimeo.com/video/${this.id}?title=0&byline=0&autoplay=1`
      }

      return null
    },
  },
})
