<template>
  <header class="header">
    <div class="header__wrapper content">

      <router-link :to="{name: 'Main'}">
        <draw-svg class="header__logo" name="logo"/>
      </router-link>

      <div class="header__controls">
        <Button theme="login" @click="authClick">
          {{ buttonText }}
          <template #append>
            <draw-svg :name="buttonSvg" width="15" height="15"/>
          </template>
        </Button>

        <Button v-if="!isAuthUser" @click="openReg">
          {{ $t('signin') }}
        </Button>

        <router-link v-else :to="{name: 'Profile'}">
          <Avatar :picture="commonStore.user?.avatar"/>
        </router-link>
      </div>
    </div>
  </header>
</template>


<script lang="ts" setup>
import { computed } from 'vue'
import { getAxiosErrorText } from '@/common/helpers'
import { api } from '@/network'

// Components
import Button from '@/components/ui/Button'
import Avatar from '@/components/ui/Avatar'
import { ElNotification } from 'element-plus'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
import { useCommonStore, useAuthModalStore } from '@/store'
const commonStore = useCommonStore()
const modalStore = useAuthModalStore()

// Router
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

// Use
import useIsUserAuth from '@/hooks/useIsUserAuth'

// Computed
const isAuthUser = computed<boolean>(() => useIsUserAuth())
const buttonText = computed<string>(() => isAuthUser.value ? t('logout') : t('login'))
const buttonSvg = computed<string>(() => isAuthUser.value ? 'logout' : 'login')

// Methods
function openReg(): void {
  modalStore.openReg()
}

async function logOut(): Promise<void> {
  commonStore.loading = true

  try {
    localStorage.removeItem('token')
    api.http.setToken(null)
    commonStore.user = null
    if (route.name !== 'Main') {
      await router.push({ name: 'Main' })
    }
  } catch (e: any) {
    ElNotification({
      title: t('common.error'),
      message: getAxiosErrorText(e),
      type: 'error',
    })
  } finally {
    commonStore.loading = false
  }
}

function authClick(): void {
  if (isAuthUser.value) {
    logOut()
  } else {
    modalStore.openLogin()
  }
}

</script>

<style lang="less">
@import "@/common/less/dependence.less";

.header {
  .debug(false);
  background: rgba(255, 255, 255, 0.02);

  &__logo {
    .size(160px, 38px);
  }

  &__controls {
    .flex(@gap: 20px);
  }

  &__wrapper {
    .flex(space-between);
    height: 68px;
  }
}
</style>