<template>
  <div class="earned-card">
    <div class="earned-card__top">
      <div class="earned-card__title">
        {{ $t('earned') }}:
      </div>
      <div class="earned-card__money">
        <Title theme="medium">
          <Money :money="commonStore.user?.earned"/>
        </Title>
      </div>
    </div>
    <div v-if="activeCard" class="earned-card__bottom">
      <div class="earned-card__number">
        **** **** **** {{ activeCard.number }}
      </div>
      <div class="earned-card__payment-system">
        <draw-svg :name="activeCard.paymentSystem" width="53" height="53"/>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

// Store
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()

// Types
import type { CardType } from '@/store/common'

// Components
import Money from '@/components/ui/Money'
import Title from '@/components/ui/Title'

// Computed
const activeCard = computed<CardType | null>(() => commonStore.user?.cards?.find(item => item.active) ?? null)
</script>
<style lang="less">
@import "@/common/less/dependence.less";

.earned-card {
  .debug(false);
  .size(360px, 212px);
  .flex(space-between, flex-start, column);
  border-radius: 15px;
  background: linear-gradient(@gradient-pink);
  padding: 30px;

  &__title {
    margin-bottom: 10px;
  }

  &__number {
    font-size: @text-size-tiny;
  }

  &__bottom {
    width: 100%;
    .flex(space-between);
  }
}
</style>