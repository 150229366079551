<template>
  <div class="registration">
    <Title class="registration__title" :theme="['modal', 'centered']">
      {{ $t('registrationModal.title') }}
    </Title>


    <!-- reg form -->
    <form v-if="modalStore.registration.screen === 'index'" @submit.prevent="tryToCheckRegister">

      <!-- login -->
      <div class="registration__input">
        <InputText
            name="login"
            focus
            v-model="modalStore.registration.name"
            :label="$t('registrationModal.name')"
        />
      </div>
      <!-- #login -->

      <!-- tg -->
      <div class="registration__input">
        <InputText
            theme="tg"
            name="tg"
            v-model="modalStore.registration.login"
            :label="$t('registrationModal.login')"
        />
      </div>
      <!-- #tg -->

      <!-- agree -->
      <div class="registration__input">
        <el-checkbox v-model="modalStore.registration.agree">
          {{ $t('registrationModal.agree.text') }}
          <router-link :to="{name: 'Main'}">{{ $t('registrationModal.agree.link') }}</router-link>
        </el-checkbox>
      </div>
      <!-- #agree -->

      <!-- #submit -->
      <div class="registration__submit">
        <Button :theme="['stretched', 'huge']" :disabled="!isFormValid">
          {{ $t('registrationModal.submit.first') }}
        </Button>
      </div>
      <!-- submit -->

      <div class="registration__text">
        {{ $t('registrationModal.hasAccount.text') }}
        <span @click="modalStore.openLogin()">{{ $t('registrationModal.hasAccount.link') }}</span>
      </div>

      <div class="registration__tg">
        <Button type="a" theme="tg" :href="$t('common.buttons.chat.link')">
          {{ $t('common.buttons.chat.title') }}
          <template #append>
            <draw-svg name="tg" width="18" height="18"/>
          </template>
        </Button>
      </div>
    </form>
    <!-- #reg form -->

    <!-- code form -->
    <form v-if="modalStore.registration.screen === 'code'" @submit.prevent="tryToFinishRegister">
      <!--      <div class="login__login">-->
      <!--        {{ $t('loginModal.useLogin') }}: @{{ modalStore.registration.login }}-->
      <!--      </div>-->

      <div class="registration__reg-text">
        {{ $t('registrationModal.text') }}
        <a :href="regLink" target="_blank">@{{ $t('common.bot.name') }}</a>
      </div>

      <!-- code -->
      <div class="login__input">
        <InputText
            name="code"
            v-model="modalStore.registration.code"
            :label="$t('registrationModal.getCode')"
        />
      </div>
      <!-- #code -->

      <!-- submit -->
      <div class="login__submit">
        <Button :theme="['stretched', 'huge']" :disabled="!isCodeValid">
          {{ $t('registrationModal.submit.second') }}
        </Button>
      </div>
      <!-- #submit -->

      <!-- tg button -->
      <div class="login__tg">
        <Button type="a" theme="tg" :href="$t('common.buttons.chat.link')">
          {{ $t('common.buttons.chat.title') }}
          <template #append>
            <draw-svg name="tg" width="18" height="18"/>
          </template>
        </Button>
      </div>
      <!-- #tg button -->

      <!-- hint -->
      <div class="login__hint">
        {{ $t('registrationModal.hint') }}
      </div>
      <!-- #hint -->
    </form>
    <!-- #code form -->

  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { i18n } from '@/locales/i18n'
import { getAxiosErrorText } from '@/common/helpers'

// Store
import { useAuthModalStore, useCommonStore } from '@/store'

const modalStore = useAuthModalStore()
const commonStore = useCommonStore()

// Components
import Button from '@/components/ui/Button'
import Title from '@/components/ui/Title'
import InputText from '@/components/inputs/InputText'
import { ElNotification, ElCheckbox } from 'element-plus'

// Methods
function showError(e: any): void {
  ElNotification({
    title: i18n.t('common.error'),
    message: getAxiosErrorText(e),
    type: 'error',
  })
}


// Computed
const isFormValid = computed<boolean>(() =>
    Boolean(
        modalStore.registration?.login
        && modalStore.registration?.login?.length >= 2
        && modalStore.registration?.name
        && modalStore.registration?.name?.length >= 2
        && modalStore.registration?.agree,
    ),
)
const isCodeValid = computed<boolean>(() => Boolean(modalStore.registration?.code && modalStore.registration?.code?.length >= 4))

const regLink = computed<string>(() => `https://t.me/TopsellerMP_bot?start=${modalStore.registration?.login}`)

// Methods
async function tryToCheckRegister() {
  modalStore.registration.screen = 'code'
  // modalStore.loading = true
  // try {
  //   const data = {
  //     name: modalStore?.registration?.name,
  //     login: modalStore?.registration?.login,
  //   }
  //   await modalStore.createLogin(data)
  //   modalStore.registration.screen = 'code'
  // } catch (e) {
  //   showError(e)
  // } finally {
  //   modalStore.loading = false
  // }
}

async function tryToFinishRegister() {
  modalStore.loading = true
  try {
    const data = {
      welcome_name: modalStore?.registration?.name,
      username: modalStore?.registration?.login,
      code_confirm: Number(modalStore?.registration?.code),
    }
    const token = (await modalStore.createAccount(data)).data.token
    localStorage.setItem('token', token)
    const user = await commonStore.getUser()
    commonStore.user = user
    modalStore.$reset()
  } catch (e) {
    showError(e)
  } finally {
    modalStore.loading = false
  }
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.registration {
  .debug(false);
  max-width: 300px;

  &__title {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 20px;
    text-align: center;
    font-size: @text-size-tiny;
  }

  &__reg-text {
    margin-bottom: 20px;
    text-align: center;
    font-size: @text-size-medium;
  }

  &__reg-text a {
    .transition();
    color: @color-white;
    font-weight: 700;

    &:hover {
      color: @color-light;
    }
  }

  &__text a {
    .transition();
    color: @color-blue;
    font-weight: 700;

    &:hover {
      color: @color-light;
    }
  }

  &__input {
    margin-bottom: 11px;
  }

  &__submit {
    margin-bottom: 15px;
  }

  &__tg {
    text-align: center;
  }

  &__login {
    margin-bottom: 20px;
  }

  &__hint {
    margin-top: 15px;
    font-size: @text-size-micro;
    font-weight: 500;
    text-align: center;
  }

  &__text {
    font-size: @text-size-small;
    font-weight: 500;
    line-height: 1.1;

    span {
      .transition();
      color: @color-blue;
      cursor: pointer;

      &:hover {
        color: @color-light;
      }
    }
  }
}
</style>