<template>
  <div class="task-controls">
    <Button :theme="['black', 'task-control']" @click="prev">
      {{ backText }}
    </Button>

    <Button theme="task-control" @click="next">
      {{ nextText }}
    </Button>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const emit = defineEmits<{
  (e: 'nextStep'): void;
  (e: 'prevStep'): void;
}>()

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
import { useTaskStore } from '@/store'
const taskStore = useTaskStore()

// Components
import Button from '@/components/ui/Button'

// Computed
const backText = computed<string>(() => {
  return (taskStore.isMainScreen) ? t('task.controls.back.first') : t('task.controls.back.other')
})
const nextText = computed<string>(() => {
  if (taskStore.isMainScreen) {
    return t('task.controls.next.first')
  }
  if (taskStore.isLastScreen) {
    return t('task.controls.next.last')
  }
  return t('task.controls.next.other')
})

// Methods
function next() {
  emit('nextStep')
}

function prev() {
  emit('prevStep')
}
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.task-controls {
  .debug(false);
  .flex(flex-start, @gap: 20px, @direction: row);
}
</style>