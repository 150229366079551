<template>
  <footer class="footer">
      <Auth v-if="isAuthUser"/>
      <UnAuth v-else/>
  </footer>
</template>


<script lang="ts" setup>
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed } from 'vue'

// Components
import UnAuth from './UnAuth.vue'
import Auth from './Auth.vue'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import useIsUserAuth from '@/hooks/useIsUserAuth'
const isAuthUser = computed<boolean>(()=> useIsUserAuth())

</script>

<style lang="less">
@import "@/common/less/dependence.less";

.footer {
  .debug(false);
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>