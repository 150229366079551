import axios from 'axios'
import type { AxiosInstance, AxiosPromise } from 'axios'

type DataType = FormData | undefined | null;
type ParamsType = object | undefined;

export class Http {
  baseUrl: string
  #Axios: AxiosInstance
  token: string | null
  defaultHeaders: Record<string, string>


  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.token = localStorage.getItem('token') ?? null
    this.defaultHeaders = {
      'content-type': 'application/x-www-form-urlencoded',
    }

    let headers = this.defaultHeaders

    if (this.token) {
      headers = Object.assign(this.defaultHeaders, {
        Authorization: `Bearer ${this.token}`,
      })
    }

    this.#Axios = axios.create({
      headers: headers,
    })
  }

  getFullUrl(url: string): string {
    return this.baseUrl ? new URL(url, this.baseUrl).href : url
  }

  get(url: string, params: ParamsType): AxiosPromise | void {
    return this.#Axios.get(this.getFullUrl(url), params)
  }

  post(url: string, data: DataType, params: ParamsType): AxiosPromise {
    return this.#Axios.post(this.getFullUrl(url), data, params)
  }

  put(url: string, data: DataType, params: ParamsType): AxiosPromise {
    return this.#Axios.put(this.getFullUrl(url), data, params)
  }

  delete(url: string, params: ParamsType): AxiosPromise {
    return this.#Axios.delete(this.getFullUrl(url), params)
  }

  setToken(token: string | null) {
    this.token = token
    let headers = this.defaultHeaders
    if (this.token) {
      headers = Object.assign(this.defaultHeaders, {
        Authorization: `Bearer ${this.token}`,
      })
    }
    this.#Axios = axios.create({
      headers: headers,
    })
  }
}
