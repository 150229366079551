<template>
  <div class="modal" :class="[themeClassName]" @click="doCloseByBg">
    <div class="modal__content" @click.stop="" :class="{'modal__content_loading': loading}">
      <div class="modal__close" v-if="showCloseButton">
        <Button theme="icon-padding" @click="close">
          <draw-svg name="close" width="10" height="10"/>
        </Button>
      </div>
      <slot/>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { onBeforeUnmount, onMounted, watch, ref, computed } from 'vue'

// Use
import { useScrollLock } from '@vueuse/core'
import useThemeClassName from '@/hooks/useThemeClassName'

// Components
import Button from '@/components/ui/Button'

// Data
const body = ref<HTMLElement | null>(document.body)
const isLocked = useScrollLock(body)

// Types
import type { Props } from './index'

// Props
const props = withDefaults(defineProps<Props>(), {
  closeByBg: true,
  showCloseButton: true,
  loading: false,
  closeWhileLoading: false,
})

const emit = defineEmits<{
  'close': [void];
}>()

// Computed
const themeClassName = computed(() => useThemeClassName('modal', props.theme))

// Methods
function close(): void {
  if (props.loading && !props.closeWhileLoading) {
    return
  }
  emit('close')
}

function doCloseByBg(): void {
  if (props.closeByBg) {
    close()
  }
}

onMounted(() => {
  isLocked.value = true
})

onBeforeUnmount(() => {
  isLocked.value = false
})
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.modal {
  .absoluteFillSize(@position: fixed);
  .flex(@items: var(--moda-vertical, center));
  overscroll-behavior: contain;
  background: rgba(16, 11, 34, 0.80);
  backdrop-filter: blur(5px);
  z-index: @z-index-modal;
  overflow: auto;
  padding: 70px;

  &__content {
    .loading(@spinner-size: 48px);
    min-width: 300px;
    position: relative;
    z-index: 20;
    padding: 30px;
    border-radius: 30px;
    background: @color-modal-bg;
    margin: var(--moda-margin-conent, auto);
  }

  &_top {
    --moda-vertical: flex-start;
    --moda-margin-conent: 0 auto auto;
  }

  &__close {
    .flex(flex-end);
    margin-bottom: 10px;
  }
}
</style>