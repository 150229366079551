<template>
  <div class="login">
    <Title class="login__title" :theme="['modal', 'centered']">
      {{ $t('loginModal.title') }}
    </Title>


    <!-- login form -->
    <form v-if="modalStore.login.screen === 'index'" @submit.prevent="tryToCheckLogin">

      <!-- tg -->
      <div class="login__input">
        <InputText
            focus
            theme="tg"
            name="login"
            v-model="modalStore.login.login"
            :label="$t('loginModal.login')"
        />
      </div>
      <!-- #tg -->

      <!-- submit -->
      <div class="login__submit">
        <Button :theme="['stretched', 'huge']" :disabled="!isLoginValid">
          {{ $t('loginModal.getCode') }}
        </Button>
      </div>
      <!-- #submit -->

      <!-- #tg button -->
      <div class="login__tg">
        <Button type="a" theme="tg" :href="$t('common.buttons.chat.link')">
          {{ $t('common.buttons.chat.title') }}
          <template #append>
            <draw-svg name="tg" width="18" height="18"/>
          </template>
        </Button>
      </div>
      <!-- #tg button -->

    </form>
    <!-- #login form -->

    <!-- code form -->
    <form v-if="modalStore.login.screen === 'code'" @submit.prevent="tryToCheckCode">

      <div class="login__text">
        {{ $t('loginModal.text') }}
        <a :href="$t('common.bot.link')">@{{ $t('common.bot.name') }}</a>
      </div>

      <div class="login__login">
        {{ $t('loginModal.useLogin') }}: @{{ modalStore.login.login }}
      </div>

      <!-- code -->
      <div class="login__input">
        <InputText
            name="code"
            focus
            v-model="modalStore.login.code"
            :label="$t('loginModal.getCode')"
        />
      </div>
      <!-- #code -->

      <!-- submit -->
      <div class="login__submit">
        <Button :theme="['stretched', 'huge']" :disabled="!isCodeValid">
          {{ $t('loginModal.confirm') }}
        </Button>
      </div>
      <!-- #submit -->

      <!-- tg button -->
      <div class="login__tg">
        <Button type="a" theme="tg" :href="$t('common.buttons.chat.link')">
          {{ $t('common.buttons.chat.title') }}
          <template #append>
            <draw-svg name="tg" width="18" height="18"/>
          </template>
        </Button>
      </div>
      <!-- #tg button -->

      <!-- hint -->
      <div class="login__hint">
        {{ $t('loginModal.hint') }}
      </div>
      <!-- #hint -->
    </form>
    <!-- #code form -->
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { i18n } from '@/locales/i18n'
import { getAxiosErrorText } from '@/common/helpers'

// Store
import { useAuthModalStore, useCommonStore } from '@/store'

const modalStore = useAuthModalStore()
const commonStore = useCommonStore()

// Components
import Button from '@/components/ui/Button'
import Title from '@/components/ui/Title'
import InputText from '@/components/inputs/InputText'
import { ElNotification } from 'element-plus'
import { api } from '@/network'

// Computed
const isLoginValid = computed<boolean>(() => Boolean(modalStore.login?.login && modalStore.login?.login?.length >= 2))
const isCodeValid = computed<boolean>(() => Boolean(modalStore.login?.code && modalStore.login?.code?.length >= 4))

// Methods
function showError(e: any): void {
  ElNotification({
    title: i18n.t('common.error'),
    message: getAxiosErrorText(e),
    type: 'error',
  })
}

async function tryToCheckLogin() {
  modalStore.loading = true
  try {
    await modalStore.checkLogin(modalStore?.login?.login as string)
    modalStore.login.screen = 'code'
  } catch (e) {
    showError(e)
  } finally {
    modalStore.loading = false
  }
}

async function tryToCheckCode() {
  modalStore.loading = true
  try {
    const token = (await modalStore.checkCode(modalStore?.login?.login as string, modalStore?.login?.code as string)).data.token
    localStorage.setItem('token', token)
    api.http.setToken(token)
    const user = await commonStore.getUser(token)
    commonStore.user = user
    modalStore.$reset()
  } catch (e) {
    showError(e)
  } finally {
    modalStore.loading = false
  }
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.login {
  .debug(false);
  max-width: 300px;

  &__title {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 20px;
    text-align: center;
    font-size: @text-size-tiny;
  }

  &__text a {
    .transition();
    color: @color-blue;
    font-weight: 700;

    &:hover {
      color: @color-light;
    }
  }

  &__input {
    margin-bottom: 20px;
  }

  &__submit {
    margin-bottom: 30px;
  }

  &__tg {
    text-align: center;
  }

  &__login {
    margin-bottom: 20px;
  }

  &__hint {
    margin-top: 15px;
    font-size: @text-size-micro;
    font-weight: 500;
    text-align: center;
  }
}
</style>