import { defineStore } from 'pinia'
import { api } from '@/network'
import type { DataType, IdType, PaymentSystemType } from '@/common/types'

export interface CardType {
  id: IdType
  number: string
  paymentSystem: PaymentSystemType
  active: boolean
}

export interface UserType {
  name: string
  avatar: string
  earned?: number
  tg_username: string
  cards: null | Array<CardType>
}

export interface CommonStateType {
  loading: boolean
  init: boolean
  user: UserType | null
}

export const useCommonStore = defineStore({
  id: 'common',

  state: () =>
    <CommonStateType>{
      loading: false,
      init: false,
      user: null,
      screen: '',
    },

  actions: {
    async getUser(token: string | undefined = undefined): Promise<UserType> {
      return await api.profile.getUser(token)
    },
  },
})
