import InputCard from './InputCard.vue'
import type { HintType } from '@/store/task'
import type { maskNames } from '@/common/mask'

export interface Props {
  label?: string
  placeholder?: string
  name?: string
  modelValue?: any
  value?: string | number
  hint?: HintType
  mask?: maskNames
  error?: string | null | undefined
}

export default InputCard