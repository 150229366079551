import Modal from './Modal.vue'

export type Themes = "top" | "center"

export interface Props {
  theme?: Themes | Array<Themes>
  closeByBg?: boolean
  showCloseButton?: boolean
  loading?: boolean
  closeWhileLoading?: boolean
}

export default Modal