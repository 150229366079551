<template>
  <div class="conditions" :class="[themeClassName]">
    <span class="conditions__title">{{ $t('conditions.title') }}:</span>
    <IconedText :theme="iconedTextTheme">
      <template #icon>
        <draw-svg name="wallet" width="38" height="38"/>
      </template>
      {{ conditionText }}
    </IconedText>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Types
import { Props } from './index'

// Components
import IconedText from '@/components/ui/IconedText'

// Props
const props = defineProps<Props>()

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Computed
const conditionText = computed<string>(() => {
  if (props.conditions === 'full') {
    return t('conditions.full')
  } else {
    return `${t('conditions.refund')} ${props.conditions} %`
  }
})
const themeClassName = computed(() => useThemeClassName('conditions', props.theme))
const iconedTextTheme = computed(()=> (props.theme === 'big') ? 'font-default' : 'big')
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.conditions {
  .debug(false);

  &__title {
    font-size: var(--conditions-font-size, @text-size-small);
    margin-bottom: var(--conditions-margin-bottom, 5px);
  }

  &_big {
    --conditions-font-size: @text-size-default;
  }
}
</style>