<template>
  <div class="profile content">
    <Title class="profile__title" theme="huge">
      {{ $t('profile.title') }}
    </Title>

    <div class="profile__grid">
      <div class="profile__grid-item">

        <div class="profile__max-width">

          <div class="profile__avatar" :class="{'profile__avatar_loading': uploadAvatarLoading}">
            <Avatar :picture="commonStore.user?.avatar" theme="huge"/>
            <Button :theme="['black', 'icon-padding']" class="profile__upload-button">
              <draw-svg name="upload" width="32" height="32"/>
            </Button>
            <input ref="input" id="avatar-upload" type="file" @change="tryUploadAvatar" :accept="acceptAvatar">
          </div>

          <div class="profile__info">
            <span>{{ $t('profile.name') }}</span>
            <span>{{ commonStore.user?.name }}</span>
            <Button @click="editName">
              {{ $t('profile.edit') }}
            </Button>
          </div>
          <div class="profile__info">
            <span>{{ $t('profile.tg') }}</span>
            <span>@{{ commonStore.user?.tg_username }}</span>
            <!--            <Button>-->
            <!--              {{ $t('profile.edit') }}-->
            <!--            </Button>-->
          </div>
        </div>
      </div>

      <div class="profile__grid-item"></div>

      <div class="profile__grid-item">
        <EarnedCard class="profile__earned-card"/>
        <Cards @add="addCard" @setDefault="setDefault" @delete="deleteCard"/>
      </div>
    </div>

  </div>

  <Modal
      theme="top"
      v-if="profileStore.modal.show"
      @close="closeModal"
      :loading="profileStore.modal.loading"
  >

    <!-- Change name -->
    <form
        class="profile__modal-form"
        v-if="profileStore.modal.display === 'name'"
        @submit.prevent="tryToChangeName"
    >

      <Title :theme="['modal', 'centered']">
        {{ $t('profile.changeNameTitle') }}
      </Title>

      <!-- name -->
      <div>
        <InputText
            name="name"
            v-model="name"
            focus
            :label="$t('profile.newName')"
        />
      </div>
      <!-- #name -->

      <!-- submit -->
      <div>
        <Button :theme="['stretched', 'huge']" :disabled="!isNameValid">
          {{ $t('profile.submit') }}
        </Button>
      </div>
      <!-- #submit -->

    </form>
    <!-- #Change name -->


    <!-- Add card -->
    <form
        class="profile__modal-form"
        v-if="profileStore.modal.display === 'card'"
        @submit.prevent="tryToAddCard"
    >

      <Title :theme="['modal', 'centered']">
        {{ $t('profile.addCardTitle') }}
      </Title>

      <!-- name -->
      <div>
        <InputText
            name="name"
            v-model="card"
            focus
            mask="card"
            :label="$t('profile.newCard')"
        />
      </div>
      <!-- #name -->

      <!-- submit -->
      <div>
        <Button :theme="['stretched', 'huge']" :disabled="!isCardValid">
          {{ $t('profile.submit') }}
        </Button>
      </div>
      <!-- #submit -->

    </form>
    <!-- #Add card -->

  </Modal>
</template>


<script lang="ts" setup>
import { ref, computed } from 'vue'

// Components
import Avatar from '@/components/ui/Avatar'
import EarnedCard from '@/components/EarnedCard'
import Cards from '@/components/Cards'
import Modal from '@/components/ui/Modal'
import Button from '@/components/ui/Button'
import Title from '@/components/ui/Title'
import InputText from '@/components/inputs/InputText'
import { ElNotification } from 'element-plus'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Data
const name = ref<string>('')
const uploadAvatarLoading = ref<boolean>(false)
const input = ref()
const acceptAvatar = '.gif, .jpeg, .jpg, .png'
const maxFileSizeAvatar = 26214400 // 25 MB
const card = ref<string>('')

// Store
import { useCommonStore, useProfileStore } from '@/store'
import { getAxiosErrorText, getExtension } from '@/common/helpers'
import { IdType } from '@/common/types'
const commonStore = useCommonStore()
const profileStore = useProfileStore()

// Computed
const isNameValid = computed<boolean>(() => Boolean(name.value.length >= 2))
const isCardValid = computed<boolean>(() => Boolean(card.value.length >= 19))

// Methods
function closeModal(): void {
  profileStore.modal.show = false
  profileStore.modal.display = null
  profileStore.modal.loading = false
}

function editName(): void {
  name.value = commonStore.user?.name ?? ''
  profileStore.modal.show = true
  profileStore.modal.display = 'name'
}

function error(e: any) {
  ElNotification({
    title: t('common.error'),
    message: getAxiosErrorText(e),
    type: 'error',
  })
}

async function addCard() {
  profileStore.modal.show = true
  profileStore.modal.display = 'card'
}

async function tryToAddCard() {
  profileStore.modal.loading = true
  try {
    await profileStore.addNewCard({ card: card.value })
    commonStore.user = await commonStore.getUser()
    card.value = ''
    closeModal()
  } catch (e: any) {
    error(e)
  } finally {
    profileStore.modal.loading = false
  }
}

async function tryToChangeName() {
  profileStore.modal.loading = true
  try {
    await profileStore.changeName({ name: name.value })
    commonStore.user = await commonStore.getUser()
    closeModal()
  } catch (e: any) {
    error(e)
  } finally {
    profileStore.modal.loading = false
  }
}

async function tryUploadAvatar(event: Event) {
  const target = event.target as HTMLInputElement
  let file = target.files?.[0] ?? null
  if (!file?.name) {
    return
  }

  const ext = getExtension(file!.name).toLowerCase() ?? null
  const isAllow = acceptAvatar.includes(ext)
  let hasError = false

  if (!isAllow) {
    error(t('profile.extError'))
    hasError = true
  }

  if (file.size && file.size > maxFileSizeAvatar) {
    error(t('profile.maxSizeError'))
    hasError = true
  }

  if (!hasError) {
    uploadAvatarLoading.value = true
    try {
      await profileStore.uploadAvatar({ file })
      commonStore.user = await commonStore.getUser()
      closeModal()
    } catch (e: any) {
      error(e)
    } finally {
      uploadAvatarLoading.value = false

    }
  }
}

async function setDefault(id: IdType) {
  commonStore.loading = true
  try {
    await profileStore.setDefaultCard(id)
    commonStore.user = await commonStore.getUser()
  } catch (e: any) {
    error(e)
  } finally {
    commonStore.loading = false
  }
}

async function deleteCard(id: IdType) {
  commonStore.loading = true
  try {
    await profileStore.deleteCard(id)
    commonStore.user = await commonStore.getUser()
  } catch (e: any) {
    error(e)
  } finally {
    commonStore.loading = false
  }
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.profile {

  &__title {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__max-width {
    .debug(false, blue);
    max-width: 300px;
  }

  &__grid {
    .debug(false);
    .flex(flex-start, stretch, @gap: 70px);
  }

  &__grid-item {
    .debug(false);

    &:nth-child(2) {
      .size(1px, auto);
      min-width: 1px;
      background-color: rgba(255, 255, 255, .6);
    }

    //&:nth-child(3) {
    //  flex: 1 0 auto;
    //}
  }

  &__earned-card {
    margin-bottom: 17px;
  }

  &__info * {
    .flex(flex-start, flex-start, @direction: column);

    &:nth-child(1) {
      color: @color-grey;
      font-size: @text-size-tiny;
      margin-bottom: 10px;
    }

    &:nth-child(2) {
      font-weight: 700;
      font-size: @text-size-medium;
      margin-bottom: 15px;
    }

    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }

  &__modal-form {
    .flex(@direction: column, @gap: 24px);
    min-width: 300px;

    > * {
      width: 100%;
    }
  }

  &__avatar {
    .debug(false);
    .loading();
    margin-bottom: 20px;
    position: relative;

    input[type=file] {
      .square(100%);
      .absoluteFillSize;
      opacity: 0;
      cursor: pointer;
      z-index: 20;
    }
  }

  &__upload-button {
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 10;
    pointer-events: none;
  }
}
</style>