<template>
  <div v-if="info" class="task-info">

    <!-- info -->
    <div class="task-info__item">
      <div class="task-info__id">#{{ info.id }}</div>
      <div class="task-info__name">{{ info.name }}</div>
      <div class="task-info__price">
        <span>{{ info.priceText }}</span>
        <money tag="div" :money="info.price"/>
      </div>

      <Conditions class="task-info__conditions" :conditions="info.conditions" theme="big"/>

      <TaskControls @nextStep="startTask" @prevStep="taskStore.prevStep()"/>

    </div>
    <!-- #info -->

    <!-- instruction -->
    <div class="task-info__item task-info__video" v-if="info.video || info.link">

      <template v-if="info.video">
        <!-- video -->
        <div class="task-info__video-title">{{ $t('task.video.title') }}</div>
        <div class="task-info__video-desc">{{ $t('task.video.desc') }}</div>
        <VideoPreview class="task-info__video-play" @click="videoStore.openVideo(info.video)" :link="info.video"/>
        <!-- #video -->
      </template>

      <!-- link -->
      <IconedText v-if="info.link" class="task-info__video-link" :theme="['center', 'link', 'font-default']">
        <a :href="info.link" target="_blank">{{ $t('task.video.link') }}</a>
        <template #iconAppend>
          <draw-svg name="download" width="15" height="15"/>
        </template>
      </IconedText>
      <!-- link -->

    </div>
    <!-- #instruction -->

    <!-- photo -->
    <div v-if="info.img" class="task-info__item task-info__image">
      <img :src="info.img.x1" :srcset="srcset">
    </div>
    <!-- #photo -->
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Store
import { useTaskStore, useCommonStore, useVideoStore } from '@/store'
const taskStore = useTaskStore()
const commonStore = useCommonStore()
const videoStore = useVideoStore()

// Components
import Conditions from '@/components/Conditions'
import Money from '@/components/ui/Money'
import VideoPreview from '@/components/VideoPreview'
import IconedText from '@/components/ui/IconedText'
import TaskControls from '@/components/TaskControls'
import { ElNotification } from 'element-plus'
import { i18n } from '@/locales/i18n'
import { getAxiosErrorText } from '@/common/helpers'

// Computed
const info = computed(() => taskStore.task?.info)
const srcset = computed<string>(() => {
  if (!info.value?.img) {
    return ''
  }
  const result = [`${info.value?.img.x1} 1x`]

  if (info.value?.img.x2) {
    result.push(`${info.value?.img.x2} 2x`)
  }

  if (info.value?.img.x3) {
    result.push(`${info.value?.img.x2} 3x`)
  }

  return result.join(', ')
})

// Methods
async function startTask() {
  const id = info.value?.id ?? ''
  commonStore.loading = true
  try {
    await taskStore.startTask(id)
    taskStore.task = await taskStore.getTask(id)
    // await taskStore.nextStep()
  } catch (e) {
    ElNotification({
      title: i18n.t('common.error'),
      message: getAxiosErrorText(e),
      type: 'error',
    })
  } finally {
    commonStore.loading = false
  }
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.task-info {
  .debug(false);
  .flex(space-between, flex-start, @gap: 33px, @wrap: wrap);
  margin: 50px 0;

  &__item {
    .debug(false);
    max-width: 367px;
  }

  &__id {
    font-size: @text-size-tiny;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__name {
    font-size: @text-size-big;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__price {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;

    span {
      font-size: @text-size-default;
      font-weight: 500;
      display: block;
      margin-bottom: 5px;
    }
  }

  &__conditions {
    margin-bottom: 68px;
  }

  &__video {
    text-align: center;
  }

  &__video-title {
    font-size: @text-size-medium;
    margin-bottom: 15px;
  }

  &__video-desc {
    font-size: @text-size-small;
    margin-bottom: 20px;
  }

  &__video-play {
    margin-bottom: 20px;
  }

  &__video-link,
  &__video-link a {
    color: @color-white;
    text-decoration: none;
  }

  &__image {
    .flex();
    aspect-ratio: 360 / 341;
    width: 100%;
    max-width: 360px;
    border-radius: 15px;
    overflow: hidden;

    img {
      .square(100%);
      object-fit: cover;
    }
  }
}
</style>