/**
 * Преобразует объект или массив в URL строку для GET запроса
 * const query = makeParams({
 *   id: 1,
 *   some: 'test',
 *   candidates: [1, 3, 5]
 * })
 * // id=1&some=test&candidates[]=1&candidates[]=3&candidates[]=5
 @param {Object | Array} query - Объект или массив для преобразования в URL
 @returns {String}
 */
import { IdType } from '@/common/types'
export const makeParams = (query: Record<string, string | []> | undefined): string => {
  if (!query) {
    return ''
  }
  let url = ''
  for (const item in query) {
    if (!Array.isArray(query[item])) {
      url += `&${item}=${query[item]}`
    } else {
      for (const sub of query[item]) {
        url += `&${item}[]=${sub}`
      }
    }
  }
  return Object.keys(url).length > 0 ? `?${new URLSearchParams(url)}` : ''
}

/**
 * Определяем является ли строкой
 @param s
 @returns {boolean}
 */
export const isString = (s: any): boolean => {
  return typeof s === 'string' || s instanceof String
}

/**
 * Определяем является ли числом
 * @param e
 * @returns {boolean}
 */
export const isNumber = (e: any) => {
  return /\d/.test(e.key)
}

/**
 * Добавляет определённый символ к строке, если общее количество символов меньше "max"
 * let i = 9;
 * padLeft(i, 2); // вернёт 09
 * padLeft(i, 4); // вернёт 0009
 * padLeft(i, 4, '*'); // вернёт ***9
 @param {string} str - Строка, с которой работаем
 @param {number} countSymbols - Необходимое количество символов
 @param {?string} addStr - Символ, которым заполняем пустоту
 @returns {string}
 */
export function padLeft(
  str: string,
  countSymbols: number,
  addStr: string = '0',
): string {
  return Array(countSymbols - String(str).length + 1).join(addStr) + str
}

/**
 * Генератор ID
 @returns {string}
 */
export const idGenerator = () => {
  return Math.random().toString(36).substring(2, 9)
}

/**
 *  Вызывает коллбэк через определенный интервал времени
 *  Таймер обнуляется при повторном вызове
 @param {function} callback - функция, которая должна отработать по таймеру
 @param {int} delay - задержка в милисикундах
 @param {int} index - индекс нужен, если на странице используется более одного "unRepeat"
 @returns {function}
 */
const timers: Record<string | number, typeof setTimeout | any> = {}
export const denounce = (
  callback: () => any,
  delay: number,
  index: string | number = 0,
) => {
  if (typeof timers[index] !== 'undefined') {
    clearTimeout(timers[index])
  }
  timers[index] = setTimeout(() => {
    callback()
  }, delay)
}

/**
 * Форматирует число в денежный формат
 @param {number} n - Сумма
 @param addZeroCents - добавлять ли нули к центам (0.0 или 0.00)
 @returns {string}
 */
export const formatMoney = (
  n: any,
  addZeroCents = false,
  separator = ',',
): string => {
  if (typeof n !== 'number') {
    n = parseFloat(n)
  }

  const string: string = n
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1&thinsp;')
    .split('.')
  let cents = ''

  if (addZeroCents) {
    cents = `${separator}${string[1]}`
  } else {
    cents = string[1] === '00' ? '' : `${separator}${string[1]}`
  }

  return string[0] + cents
}

/**
 * Возвращает расширение файла
 @param {string} name - Имя или путь до файла
 @returns {string}
 */
export const getExtension = (name: any): string => {
  return name.split('.').pop()
}

/**
 * Метод для клонирования объекта
 @param {object} obj
 @returns {object}
 *
 */
export const clone = (obj: object | [] | null | string) => {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Метод для клонирования Proxy объекта
 @param {proxy object} obj
 @returns {object}
 *
 */
export const cloneProxy = (obj: any) => {
  const clone = Object.create(Object.getPrototypeOf(obj))
  const properties = Object.getOwnPropertyDescriptors(obj)

  for (const prop in properties) {
    // eslint-disable-next-line no-prototype-builtins
    if (properties[prop].hasOwnProperty('value')) {
      clone[prop] = properties[prop].value
    } else {
      clone[prop] = properties[prop]
    }
  }
  return clone
}

/**
 * Вытаскивает текст ошибки из ответа Axios'a
 @param {any} e
 @returns {string}
 *
 */
export const getAxiosErrorText = (e: any): string => {
  return (
    e.response?.data?.errors?.[0]?.message ?? e.response?.data?.message ?? e
  )
}

/**
 * Делает первую букву в строке заглавной
 @param {string} str
 @returns {string}
 *
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Проверяет номер карты по алгоритму "Луна"
 @param {string | number} number
 @returns {boolean}
 *
 */
export const isMoon = (number: IdType): boolean => {
  let arr = []
  let cardNumber = number.toString()

  for (let i = 0; i < cardNumber.length; i++) {
    if (i % 2 === 0) {
      const m = parseInt(cardNumber[i]) * 2
      arr.push((m > 9) ? m - 9 : m)
    } else {
      arr.push(parseInt(cardNumber[i]))
    }
  }
  const summ = arr.reduce((a, b) => {
    return a + b
  })
  // return Boolean(!(summ % 10))
  return Boolean((summ % 10) == 0)
}