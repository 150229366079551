import TaskSlider from './TaskSlider.vue'
import { IdType } from '@/common/types'

export type StatusType = 'awaitPayment' | 'awaitReview' | 'done' | 'error' | 'new'

export interface TaskType {
  id?: IdType
  status?: StatusType
  date: string
  name: string
  price: number
  price_text?: string
  conditions: 'full' | number
  img: Record<'x1' | 'x2' | 'x3', string> | null
}

export interface Props {
  title?: string
  items: Array<TaskType> | null
  routeName?: string
  isLoading?: boolean
  init?: boolean
  emptyText?: string | null
}

export default TaskSlider