import { defineStore } from 'pinia'
import { api } from '@/network'
import type { DataType, IdType } from '@/common/types'

export type ScreenType = 'index' | 'code'

export interface Login {
  login: string | null
  code: string | null
  screen: ScreenType
}

export interface Registration {
  login: string | null
  name: string | null
  code: string | null
  agree: boolean
  screen: ScreenType
}

export interface AuthModalStateType {
  loading: boolean
  show: boolean
  component: 'Login' | 'Registration' | null
  login: Login
  registration: Registration
}

export const useAuthModalStore = defineStore({
  id: 'auth-modal',

  state: () =>
    <AuthModalStateType>{
      loading: false,
      show: false,
      component: null,
      login: {
        login: null,
        code: null,
        screen: 'index',
      },
      registration: {
        login: null,
        name: null,
        code: null,
        agree: false,
        screen: 'index',
      }
    },

  actions: {
    toggleModal(force?: boolean | undefined): void {
      if (typeof force === 'undefined') {
        this.show = !this.show
      } else {
        this.show = force
      }
    },

    openReg(): void {
      this.component = 'Registration'
      this.toggleModal(true)
    },

    openLogin(): void {
      this.component = 'Login'
      this.toggleModal(true)
    },

    // Login
    checkLogin(login: string): Promise<any> {
      return api.profile.checkLogin(login)
    },

    checkCode(login: string, code: string): Promise<any> {
      return api.profile.checkCode(login, code)
    },

    // Reg
    createLogin(data: DataType): Promise<any> {
      console.log('create login')
      return api.profile.createLogin(data)
    },

    createAccount(data: DataType): Promise<any> {
      return api.profile.createAccount(data)
    },

  },
})
