export default function useThemeClassName(className: string, theme: string | Array<string> | undefined): string | null {
  if (theme) {
    if (Array.isArray(theme)) {
      return theme.map(theme => `${className}_${theme}`).join(' ')
    } else {
      return `${className}_${theme}`
    }
  }
  return null
}
