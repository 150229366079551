import { endpoints } from '@/network/endpoints'
import type { AxiosPromise } from 'axios'
import type { QueryType, IdType, DataType } from '@/common/types'
import * as mockMyTasks from '@/mocks/MyTasks'
import * as mockNewTasks from '@/mocks/NewTasks'
import type * as commonType from '@/store/common'
import { clone } from '@/common/helpers'

export default class MainApi {
  http: any

  constructor(http: any) {
    this.http = http
  }

  async getTasks(data: DataType): Promise<AxiosPromise> {
    const { type, status } = data
    if (type === 'new') {
      return (await this.http.get(endpoints.tasks.getTasks)).data
    } else {
      return (await this.http.post(endpoints.tasks.getTasks, { status })).data
    }
  }

  // Mock
  // async getTasks(data: DataType): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     const { type } = data
  //     console.log(type)
  //     setTimeout(() => {
  //       if (type === 'my') {
  //         const result = clone(mockMyTasks.myTasks)
  //         resolve(result)
  //         // reject('чот пошло не так')
  //       }
  //       if (type === 'new') {
  //         const result = clone(mockNewTasks.newTasksMock)
  //         resolve(result)
  //         // reject('чот пошло не так')
  //       }
  //     }, 2000)
  //   })
  // }
}
