<template>
  <div class="context-menu" v-click-outside="tryToClose">
    <div class="context-menu__button" @click="toggle">
      <draw-svg name="drop-menu-dots" width="20" height="20"/>
    </div>
    <div class="context-menu__wrapper" v-if="opened">
      <slot/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

// Types
import type { Props } from './index'

// Props
const props = withDefaults(defineProps<Props>(), {
  trigger: 0
})

// Data
const opened = ref<boolean>(false)

// Methods
function toggle(action: boolean | undefined): void {
  opened.value = (typeof action === 'boolean') ? action : !opened.value
}

function tryToClose(): void | boolean {
  if (!opened.value) {
    return false
  }
  toggle(false)
}

// Watch
watch(() => props.trigger, () => {
  toggle(false)
})
</script>


<style lang="less">
@import "/src/common/less/dependence";

.context-menu {
  .debug(false);
  position: relative;

  &__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: @color-modal-bg;
    border-radius: 10px;
    z-index: 1;
  }

  &__wrapper > *:first-child {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  &__wrapper > *:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
}
</style>