import Button from './Button.vue'

type Themes = 'login' | 'tg' | 'huge' | 'icon' | 'icon-padding' | 'stretched' | 'black' | 'task-control' | 'demo'

export interface Props {
  type?: 'router-link' | 'button' | 'a'
  theme?: Themes | Array<Themes>
  to?: {} | null
}

export default Button;