<template>
  <component :is="type" class="ui-title" v-bind="$attrs" :class="[themeClassName]">
    <slot/>
  </component>
</template>


<script lang="ts" setup>
import { computed } from 'vue'

// Types
import type { Props } from '@/components/ui/Title'

// Props
const props = withDefaults(defineProps<Props>(), {
  type: "h2",
});

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Computed
const themeClassName = computed(() => useThemeClassName('ui-title', props.theme))

</script>

<style lang="less">
@import "@/common/less/dependence.less";

.ui-title {
  .debug(false);
  font-family: var(--title-font-family, @font-medium);
  font-weight: var(--title-font-weight, initial);
  font-size: var(--title-font-size, @text-size-medium);
  line-height: var(--title-line-height, 1.1);
  color: var(--title-color, inherit);
  text-align: var(--title-text-align, inherit);

  &_centered {
    --title-text-align: center;
  }

  &_huge {
    --title-font-size: @text-size-extra-largest;
  }

  &_medium {
    --title-font-size: @text-size-big;
    --title-font-family: @font-semibold;
  }

  &_big {
    --title-font-family: @font-semibold;
    --title-font-size: @text-size-largest;
  }

  &_modal {
    --title-font-family: @font-default;
    --title-font-weight: 500;
    --title-font-size: 24px;
  }
}
</style>
