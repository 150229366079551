<template>
  <div class="task-slider">

    <div v-if="showHeader" class="task-slider__header">
      <Title theme="medium" v-if="showHeader" class="task-slider__title">
        {{ title }}
      </Title>
      <div v-if="showHeader" class="task-slider__filter">
        <slot name="filter"/>
      </div>
    </div>

    <div v-if="isEmpty && init" class="task-slider__empty" :class="{'task-slider__empty_loading': isLoading}">
      {{ EmptyText }}
    </div>

    <div v-if="!isEmpty || !init" class="task-slider__wrapper" :class="{'task-slider__wrapper_loading': isLoading}">
      <div class="task-slider__arrow task-slider__arrow_prev" ref="prev">
        <draw-svg name="arrow-pink" width="30" height="30"/>
      </div>


      <Swiper
          class="task-slider__swiper"
          slides-per-view="auto"
          :space-between="7"
          :navigation="{
          prevEl: prev,
          nextEl: next,
          disabledClass: 'task-slider__arrow_disabled'
        }"
          :modules="modules"
      >
        <SwiperSlide
            v-for="item in items"
            class="task-slider__swiper-slide"
        >
          <TaskCard :item="item"/>
        </SwiperSlide>
      </Swiper>

      <div class="task-slider__arrow task-slider__arrow_next" ref="next">
        <draw-svg name="arrow-pink" width="30" height="30"/>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, useSlots } from 'vue'

// Components
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import 'swiper/css'
import TaskCard from '@/components/TaskCard'
import Title from '@/components/ui/Title'

// i18n
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

// Types
import { Props } from '@/components/TaskSlider'

// Props
const props = withDefaults(defineProps<Props>(), {
  init: true,
  emptyText: null,
  status: 'new',
})

// Data
const prev = ref(null)
const next = ref(null)
const modules = [Navigation]
const slots = useSlots()

// Computed
const hasSlot = computed<boolean>(() => !!slots.filter)
const showHeader = computed<boolean>(() => Boolean(props.title?.length || hasSlot))
const EmptyText = computed<string>(() => props.emptyText ?? t('common.noData'))
const isEmpty = computed<boolean>(()=> Boolean(!props.items?.length))
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.task-slider {
  .debug(false);
  margin: 50px 0;

  &__empty {
    .loading();
    .flex();
    height: 400px;
    margin-top: 40px;
    padding-bottom: 50px;
    position: relative;
  }

  &__wrapper {
    .flex(space-between, @gap: 20px);
    .loading();
    height: 400px;
    border-radius: 15px;
    position: relative;
    margin-top: 40px;
    user-select: none;
    padding-bottom: 50px;
  }

  &__swiper {
    width: 100%;
  }

  &__swiper-slide {
    .debug(false, white);
    width: 260px;
  }

  &__arrow {
    .transition();

    &:not(&_disabled) {
      cursor: pointer;
    }

    svg {
      display: block;
    }

    &:hover:not(&_disabled) {
      .scale(1.5, 1.5);
    }
  }

  &__arrow_disabled {
    filter: grayscale(1);
  }

  &__arrow_next svg {
    .rotate(180deg);
  }

  &__header {
    .flex(space-between);
  }
}
</style>