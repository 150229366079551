<template>
  <div class="footer-un-auth content">
    <router-link
        :to="{name: 'Privacy'}"
        class="footer-un-auth__link"
        active-class="footer-un-auth__link_active"
        exact-active-class="footer-un-auth__link_active"
    >
      {{ $t('footer.privacy') }}
    </router-link>

    <router-link
        :to="{name: 'Agreements'}"
        class="footer-un-auth__link"
        active-class="footer-un-auth__link_active"
        exact-active-class="footer-un-auth__link_active"
    >
      {{ $t('footer.agreements') }}
    </router-link>
  </div>
</template>

<style lang="less">
@import "@/common/less/dependence.less";

.footer-un-auth {
  .debug(false);
  .flex(@gap: 30px);
  width: 100%;

  &__link {
    .animatedUnderline;
    display: inline-block;
    position: relative;
    color: @color-white;
    font-family: @font-regular;
    text-decoration: none;

    &_active {
      &::before {
        .scale(1, 1);
      }
    }
  }
}
</style>