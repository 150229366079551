import { defineStore } from 'pinia'
import { api } from '@/network'
import type { DataType, IdType } from '@/common/types'

export interface TaskType {
  loading: boolean
  items: []
  status: []
  init: boolean
}

export interface CommonStateType {
  myTasks: TaskType
  newTasks: TaskType
}

export const useMainStore = defineStore({
  id: 'main',

  state: () =>
    <CommonStateType>{
      myTasks: {
        loading: false,
        items: [],
        status: [],
        init: false
      },
      newTasks: {
        loading: false,
        items: [],
        status: [],
        init: false
      },
    },

  actions: {
    async getTasks(data: DataType): Promise<any> {
      return await api.main.getTasks(data)
    },

  },
})
