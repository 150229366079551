<template>
  <Auth v-if="isAuthUser"/>
  <UnAuth v-else/>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import UnAuth from '@/views/Main/UnAuth.vue'
import Auth from '@/views/Main/Auth.vue'

import useIsUserAuth from '@/hooks/useIsUserAuth'
const isAuthUser = computed<boolean>(()=> useIsUserAuth())

</script>