<template>

  <Error404 v-if="taskStore.init && taskStore.error && !taskStore.loading">
    {{ $t('task.404') }}
  </Error404>

  <div v-else class="task content">

    <TaskInfo v-if="taskStore.screen === 'main'"/>
    <TaskStep v-else/>

  </div>
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount } from 'vue'
import { getAxiosErrorText } from '@/common/helpers'

// Components
import Error404 from '@/views/Error404'
import TaskStep from '@/components/TaskStep'
import TaskInfo from '@/components/TaskInfo'
import { ElNotification } from 'element-plus'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Router
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const id = route.params.id.toString()

// Store
import { useTaskStore, useCommonStore } from '@/store'
const taskStore = useTaskStore()
const commonStore = useCommonStore()

// Mounted
onMounted(async () => {
  commonStore.loading = true
  try {
    taskStore.task = await taskStore.getTask(id)
  } catch (e: any) {
    taskStore.error = true
    ElNotification({
      title: t('common.error'),
      message: getAxiosErrorText(e),
      type: 'error',
    })
  } finally {
    taskStore.init = true
    commonStore.loading = false
  }
})

// UnMounted
onBeforeUnmount(() => {
  taskStore.$reset()
})
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.task {
  .debug(false);
}
</style>