<template>
  <el-tooltip
      popper-class="hint-text__bubble"
      :effect="effect"
      :content="hintText"
      :placement="placement"
      :to="to"
  >
    <component
        :is="tag"
        :class="[themeClassName]"
        v-bind="$attrs"
        class="hint-text"
    >
      {{ linkText }}
    </component>
  </el-tooltip>
</template>


<script lang="ts" setup>
import { computed } from 'vue'

// Components
import { ElTooltip } from 'element-plus'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Use
import useThemeClassName from '@/hooks/useThemeClassName'

// Types
import type { Props } from './index'

// Props
const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  placement: 'top-start',
  effect: 'light',
  to: null,
})

// Computed
const themeClassName = computed(() => useThemeClassName('hint-text', props.theme))
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.hint-text {
  .transition;
  display: var(--hint-text-display, inline-block);
  color: var(--hint-text-color, @color-link);
  font-size: var(--hint-text-size, @text-size-tiny);
  border-bottom: var(--hint-text-border-bottom, 1px solid @color-link);
  cursor: var(--hint-text-cursor, pointer);

  &:hover {
    --hint-text-color: @color-white;
    --hint-text-border-bottom: 1px solid @color-white;
  }

  &__bubble {
    color: var(--hint-bubble-text-color, @color-black);
    font-size: var(--hint-bubble-text-size, @text-size-default) !important;
    font-face: var(--hint-bubble-font-face, @font-default) !important;
  }
}
</style>
