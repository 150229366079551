/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRouter, createWebHistory, NavigationGuardNext, Router, RouteRecordRaw } from 'vue-router'

// Views
import Main from '@/views/Main'
import Profile from '@/views/Profile'
import Task from '@/views/Task'
import error404 from '@/views/Error404'

// Middlewares
import needAuth from '@/middleware/needAuth'
import checkAuth from '@/middleware/checkAuth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    meta: {
      middleware: [checkAuth],
    },
    component: Main
  },

  // Profile
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      middleware: [needAuth],
    },
    component: Profile
  },

  // Task
  {
    path: '/task/:id',
    name: 'Task',
    meta: {
      middleware: [needAuth],
    },
    component: Task
  },

  // Static
  {
    path: '/static',
    children: [
      {
        path: '/static/privacy',
        name: 'Privacy',
        meta: {
          middleware: [checkAuth],
        },
        component: () => import('@/views/StaticText'),
      },
      {
        path: '/static/agreements',
        name: 'Agreements',
        meta: {
          middleware: [checkAuth],
        },
        component: () => import('@/views/StaticText'),
      },
      {
        path: '/static/offer',
        name: 'Offer',
        meta: {
          middleware: [checkAuth],
        },
        component: () => import('@/views/StaticText'),
      },
    ],
  },

  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: error404,
    meta: {
      middleware: [checkAuth],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

type ContextType = {
  to: RouteRecordRaw
  from: RouteRecordRaw
  next: NavigationGuardNext
  router: Router
}

function nextFactory(context: ContextType, middleware: any, index: number): () => void {
  const subMiddleWare = middleware[index]
  if (!subMiddleWare) {
    return context.next
  }
  return () => {
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subMiddleWare({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to: any, from: any, next: NavigationGuardNext) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

export default router
