<template>
  <div class="cards">

    <!-- Add -->
    <div class="cards__item cards__item_add" @click="$emit('add')">
      <div class="cards__head">
        <draw-svg name="add-card" width="20" height="20"/>
      </div>
      <div class="cards__body">
        {{ $t('cards.addNewCard') }}
      </div>
    </div>
    <!-- #Add -->

    <div class="cards__item" v-for="card in commonStore.user?.cards">
      <div class="cards__head">
        <draw-svg :name="`${card.paymentSystem}-color`" width="25" height="25"/>

        <div v-if="!card.active">
          <ContextMenu :key="`ContextMenu-${card.id}-${index}`">
            <ContextMenuItem @click="setDefaultCard(card.id)">
              {{ $t('cards.setDefault') }}
            </ContextMenuItem>

            <el-popconfirm
                @confirm="confirmEvent(card.id)"
                width="210"
                hide-icon
                :title="$t('cards.confirm')"
            >
              <template #reference>
                <ContextMenuItem>
                  {{ $t('cards.delete') }}
                </ContextMenuItem>
              </template>
            </el-popconfirm>
          </ContextMenu>
        </div>

        <div v-if="card.active">
          <el-popconfirm
              @confirm="confirmEvent(card.id)"
              width="210"
              hide-icon
              :title="$t('cards.confirm')"
          >
            <template #reference>
              <draw-svg name="delete" width="20" height="20"/>
            </template>
          </el-popconfirm>
        </div>

      </div>
      {{ card.number }}
      <template v-if="card.active">
        ∙ {{ $t('cards.isDefault') }}
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ElPopconfirm } from 'element-plus'
import ContextMenu, { ContextMenuItem } from '@/components/ui/ContextMenu'

// Types
import { IdType } from '@/common/types'

// Data
const index = ref<number>(0)

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Emits
const emit = defineEmits<{
  (e: 'add'): void;
  (e: 'delete', id: IdType): IdType;
  (e: 'setDefault', id: IdType): IdType;
}>()

// Store
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()

// Methods
function setDefaultCard(id: IdType) {
  index.value++
  emit('setDefault', id)
}

function confirmEvent(id: IdType) {
  index.value++
  emit('delete', id)
}
</script>

<style lang="less">
@import "@/common/less/dependence.less";

.cards {
  .debug(false);
  .flex(flex-start, @wrap: wrap, @gap: 10px);

  &__item_add {
    cursor: pointer;
  }

  &__head {
    .flex(space-between);
    width: 100%;

    > *:nth-child(2) {
      .transition();
      cursor: pointer;
      outline: none;

      &:hover {
        color: @color-light;
      }
    }
  }

  svg {
    outline: none;
  }

  &__item {
    .debug(false);
    .size(160px, 100px);
    .flex(space-between, flex-start, @direction: column);
    .transition();
    font-weight: 500;
    border-radius: 10px;
    padding: 15px;
    background-color: @color-card-bg;
    border: 1px solid @color-blue;

    &:hover {
      box-shadow: 0 20px 40px 0 rgba(114, 78, 255, 0.3);
    }
  }

  &__item * {
    .debug(false);
  }
}
</style>