/* eslint-disable @typescript-eslint/no-unused-vars */
import { getAxiosErrorText } from '@/common/helpers'
import { useCommonStore } from '@/store'
import { ElNotification } from 'element-plus'
import type { NavigationGuardNext } from 'vue-router'
import { i18n } from '@/locales/i18n'

export default async function needAuth({ next }: { next: NavigationGuardNext }) {

  const commonStore = useCommonStore()
  const redirect = () => {
    return next({
      name: 'Main',
    })
  }

  const token = localStorage.getItem('token')

  if (!token) {
    commonStore.init = true
    return redirect()
  }

  if (!commonStore.user) {
    try {
      const user = await commonStore.getUser()
      commonStore.user = user
      return next()
    } catch (e: any) {
      ElNotification({
        title: i18n.t('common.error'),
        message: getAxiosErrorText(e),
        type: 'error',
      })
      return redirect()
    } finally {
      commonStore.init = true
    }
  } else {
    next()
  }
}
