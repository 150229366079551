<template>
  <div class="main-auth content">

    <Welcome class="main-auth__welcome"/>

    <TaskSlider
        :items="mainStore.myTasks.items"
        :is-loading="mainStore.myTasks.loading"
        :init="mainStore.myTasks.init"
        :title="$t('myTasks')"
        :emptyText="emptyText"
    >
      <template #filter>
        <div class="main-auth__filter">
          <span>{{ $t('sortBy') }}:</span>
          <el-select
              v-model="mainStore.myTasks.status"
              multiple
              collapse-tags
              collapse-tags-tooltip
              clearable
              :placeholder="$t('choose')"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
      </template>
    </TaskSlider>

    <TaskSlider
        :items="mainStore.newTasks.items"
        :is-loading="mainStore.newTasks.loading"
        :init="mainStore.newTasks.init"
        :title="$t('newTasks')"
        :emptyText="$t('main.auth.empty.newTasks')"
    />

  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'
import { watchDebounced } from '@vueuse/core'
import { myTasks } from '@/mocks/MyTasks'

// Components
import TaskSlider from '@/components/TaskSlider'
import Welcome from '@/components/Welcome'

// i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// Types
import type { StatusType } from '@/components/TaskSlider'

// Store
import { useMainStore } from '@/store'
const mainStore = useMainStore()

// Data
const options: Array<{value: StatusType, label: string}> = [
  {
    value: 'new',
    label: t('statuses.new'),
  },
  {
    value: 'done',
    label: t('statuses.done'),
  },
  {
    value: 'error',
    label: t('statuses.error'),
  },
  {
    value: 'awaitPayment',
    label: t('statuses.awaitPayment'),
  },
  {
    value: 'awaitReview',
    label: t('statuses.awaitReview'),
  },
]

// Computed
const myTasksQuery = computed(() => {
  return {
    type: 'my',
    status: mainStore.myTasks.status
  }
})
const emptyText = computed<string>(() => {
  return (mainStore.myTasks.status.length) ? t('main.auth.empty.notFound') : t('main.auth.empty.myTasks')
})

// Watch
watchDebounced(
    () => mainStore.myTasks.status,
    async () => {
      mainStore.myTasks.loading = true
      try {
        const result = await mainStore.getTasks(myTasksQuery.value)
        mainStore.myTasks.items = result
      } catch (e: any) {
      } finally {
        mainStore.myTasks.loading = false
      }
    },
    { debounce: 1500, maxWait: 5000, deep: true },
)

onMounted(async () => {
  mainStore.myTasks.loading = true
  mainStore.newTasks.loading = true

  try {
    const [myTasks, newTasks] = await Promise.allSettled([
      mainStore.getTasks(myTasksQuery.value),
      mainStore.getTasks({ type: 'new' }),
    ])
    const result = { myTasks, newTasks }
    if (result.myTasks.status === 'fulfilled') {
      mainStore.myTasks.items = result.myTasks.value
    }
    if (result.newTasks.status === 'fulfilled') {
      mainStore.newTasks.items = result.newTasks.value
    }
  } catch (e: any) {
  } finally {
    mainStore.myTasks.loading = false
    mainStore.newTasks.loading = false
    mainStore.myTasks.init = true
    mainStore.newTasks.init = true
  }
})

onBeforeUnmount(() => {
  mainStore.$reset()
})
</script>


<style lang="less">
@import "@/common/less/dependence.less";

.main-auth {
  .debug(false);

  &__welcome {
    margin-top: 44px;
  }

  &__filter {
    .flex(@gap: 15px);
  }
}
</style>